import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box, Typography, Paper, TextField, Button, CircularProgress, Tooltip } from "@mui/material";
import { formatIsoToCustomDateStringWithEEEEHHMMA, textStateColors, textStateIcons } from "../utils/utils";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FrontendText, FrontendUser, TextThreadMessage } from "../types";
import { useSnackbar } from "../providers/SnackbarProvider";
import { RootState } from "../store";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { StyledHeading } from "../Dashboard/WaitlistSlotInfo";
import { StatusMessageVariantOne, StyledToggleButtonVariantOne } from "../styles";
import { Colors } from "../Colors";
import { set } from "date-fns";
import { PrimaryButton } from "../subcomponents/CustomButton";
import SendIcon from "@mui/icons-material/Send";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;
  padding: 6px 0;
`;

const TextThreadContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 45vh;
  padding: 20px 15px;
  background: ${Colors.bg2};
  border-radius: 15px;
`;

const TextMetadata = styled(Typography)<{ role: string }>`
  font-size: 0.7rem;
  color: ${(props) => (props.role === "agent" ? "lightgrey" : "#aaa")};
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

interface TextMessageProps {
  role: "agent" | "patient" | "clinic";
}

const TextMessage = styled(({ role, ...otherProps }: TextMessageProps & React.ComponentProps<typeof Paper>) => <Paper {...otherProps} />)`
  margin-bottom: 10px;
  margin-left: ${(props: any) => (props.role === "agent" || props.role === "clinic" ? "auto" : "0px")};
  padding: 10px 15px;
  max-width: 75%;
  background-color: ${(props: any) => (props.role === "agent" || props.role === "clinic" ? "#007AFF" : Colors.bgTextMsg)} !important;
  color: ${(props: any) => (props.role === "agent" || props.role === "clinic" ? "#fff" : "#fff")} !important;
  border-radius: 15px !important;
`;

const InputContainer = styled(Box)`
  padding: 16px 0;
  position: relative;
`;

const StyledButton = styled(Button)`
  max-height: 60px;
  background-color: #007bff;
  color: #fff;
  margin-left: 8px;
  &:hover {
    background-color: #0056b3;
  }
  &:active {
    background-color: #004494;
  }
  &:disabled {
    background-color: #d6d6d6;
    color: #a0a0a0;
  }
`;

const TextThreadDisplay: React.FC<{ thread: TextThreadMessage[]; agentId: string; patientId: string }> = ({ thread, agentId, patientId }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const waitlistSlotId = useParams<{ waitlistSlotId: string }>().waitlistSlotId;
  const [threadLoading, setThreadLoading] = useState(false);
  //const [manualOverride, setManualOverride] = useState(text.manualOverride);

  //const textId = text.textId;

  const { showMessage } = useSnackbar();

  //const firstAcceptedMessage = thread.find((message) => message.state === "accepted");
  //const firstDeclinedMessage = thread.find((message) => message.state === "declined");

  const [newMessage, setNewMessage] = useState("");

  const scrollableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.scrollTop = scrollableElement.scrollHeight;
    }
  }, [thread]);

  const handleSendCustomText = async () => {
    if (!user?.userId) {
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/texts/${agentId}/send`,
        {
          patientId: patientId,
          message: newMessage,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (response.status === 200) {
        showMessage("Text sent successfully", "success");
      } else {
        throw new Error(`Failed to send custom text to patient`);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage("Session has expired", "error");
      } else {
        console.error("Error sending custom text:", error);
        // showMessage('Failed to send custom text', 'error');
      }
    }
  };

  /*
  const toggleManualOverride = async () => {
    if (!user?.userId) {
      return;
    }
    setManualOverride(!manualOverride);
    console.log(!manualOverride);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/texts/${textId}`,
        {
          manualOverride: !manualOverride,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (response.status === 200) {
        showMessage("Manual override toggled successfully", "success");
      } else {
        throw new Error(`Failed to toggle manual override`);
      }
    } catch (error: any) {
      setManualOverride(manualOverride);
      if (error.response?.status === 401) {
        showMessage("Session has expired", "error");
      } else {
        console.error("Error toggling manual override:", error);
        showMessage("Failed to toggle manual override");
      }
    }
  };
  */

  const handleSendNewMessage = async () => {
    if (newMessage.trim() === "") return;
    setNewMessage("");

    await handleSendCustomText();
  };

  return (
    <>
      <Container>
        <Typography variant={"h6"} sx={{ marginBottom: 1 }}>
          Text conversation
        </Typography>
        {threadLoading ? (
          <LoadingWithMessage message="Loading thread" size={60} />
        ) : (
          <TextThreadContainer ref={scrollableRef}>
            {thread.map((message: TextThreadMessage, index) => (
              <Box key={index} display="flex" alignItems="center" gap={1}>
                {/*
                {message === firstAcceptedMessage && message.state === "accepted" ? (
                  <Tooltip title={message.stateJustification}>
                    <Box mr={1} color={textStateColors[message.state]}>
                      {textStateIcons[message.state]}
                    </Box>
                  </Tooltip>
                ) : message === firstDeclinedMessage && message.state === "declined" ? (
                  <Tooltip title={message.stateJustification}>
                    <Box mr={1} color={textStateColors[message.state]}>
                      {textStateIcons[message.state]}
                    </Box>
                  </Tooltip>
                ) : (
                  <Box mr={1}>
                    <Box width={24} height={24} />
                  </Box>
                )}
                */}
                <Box mr={1}>
                  <Box width={24} height={24} />
                </Box>
                <TextMessage role={message.role}>
                  <Typography variant="body1" fontSize={"0.9rem"} mb={1}>
                    {message.message}
                  </Typography>
                  <TextMetadata variant="caption" role={message.role}>{`${formatIsoToCustomDateStringWithEEEEHHMMA(message.date)} - ${
                    message.role
                  }`}</TextMetadata>
                </TextMessage>
              </Box>
            ))}
          </TextThreadContainer>
        )}

        <InputContainer>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendNewMessage();
              }
            }}
            sx={{
              marginRight: 0,
              "& .MuiInputBase-input": {
                color: "#FFFFFF",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "18px",
                backgroundColor: Colors.textfield,
              },
            }}
          />

          <PrimaryButton
            variant="contained"
            onClick={() => {
              handleSendNewMessage();
            }}
            sx={{ borderRadius: "18px", position: "absolute", right: 0, top: "25%" }}
          >
            <SendIcon />
          </PrimaryButton>
        </InputContainer>

        {/*
        <Box sx={{ marginLeft: 2 }}>
          <StyledToggleButtonVariantOne value="toggle" selected={manualOverride} onChange={toggleManualOverride}>
            {manualOverride ? <ToggleOnIcon style={{ color: Colors.otherColor1 }} /> : <ToggleOffIcon style={{ color: Colors.confirmedColor1 }} />}
            <StatusMessageVariantOne>{manualOverride ? "AI texts disabled" : "AI texts enabled"}</StatusMessageVariantOne>
          </StyledToggleButtonVariantOne>
        </Box>
        */}
      </Container>
    </>
  );
};

export default TextThreadDisplay;
