import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Typography, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, OutlinedInput } from "@mui/material";
import styled from "styled-components";
import { PrimaryButton, TransparentButton } from "../subcomponents/CustomButton";
import { useSelector } from "react-redux";
import { updateWalkthroughUser } from "../slices/AuthSlice";
import { RootState, useAppDispatch } from "../store"; // Ensure useAppDispatch is imported
import { fetchProviders, fetchAppointments } from "../slices/HealthieIntegrationSlice";
import { usePostHog } from "posthog-js/react";
import { Colors } from "../Colors";
import axios from "axios";
import { useSnackbar } from "../providers/SnackbarProvider";
import { useNavigate } from "react-router-dom";
import { Agent, AgentType, EHR, Organization } from "../types";
import { completeWalkthrough } from "../slices/AuthSlice";
import { fetchAgents } from "../slices/AgentSlice";
import { isPhoneNumber } from "../utils/utils";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";

const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  margin: 5% auto;
  background-color: #fff;
  padding: 40px 5%;
  border-radius: 35px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 1250px) {
    width: 80%;
    padding: 30px 5%;
    margin: 5% auto;
  }

  @media (max-width: 480px) {
    width: 85%;
    padding: 30px 8%;
    margin: 3% auto;
  }
`;

const RowBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 15%;
  margin-bottom: 20px;

  @media (max-width: 920px) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
`;

const CreateAgentModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [agentName, setAgentName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [callbackNumber, setCallbackNumber] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [selectedProvider, setSelectedProvider] = useState<string>("");
  const [selectedAppointment, setSelectedAppointment] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");

  const posthog = usePostHog();
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const providers = useSelector((state: RootState) => state.healthieIntegration.providers);
  const appointments = useSelector((state: RootState) => state.healthieIntegration.appointments);
  const loadingProviders = useSelector((state: RootState) => state.healthieIntegration.loadingProviders);
  const loadingAppointments = useSelector((state: RootState) => state.healthieIntegration.loadingAppointments);
  const loadingPatients = useSelector((state: RootState) => state.healthieIntegration.loadingPatients);
  const [integrated, setIntegrated] = useState(false);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);

  useEffect(() => {
    if (organization) {
      if (Object.keys(organization.integrations ?? {}).includes(EHR.healthie)) {
        setIntegrated(true);
      }
    }
  }, [organization?.integrations]);

  useEffect(() => {
    if (integrated && user?.token) {
      dispatch(fetchProviders({ token: user.token }));
    }
  }, [integrated, user, dispatch]);

  useEffect(() => {
    if (integrated && user?.token && providers.length > 0) {
      dispatch(fetchAppointments({ token: user.token, providerIds: providers.map((provider) => provider.id) }));
    }
  }, [providers, integrated, user, dispatch]);

  const handleProviderChange = (event: SelectChangeEvent<string>) => {
    const providerId = event.target.value;
    setSelectedProvider(providerId);
  };

  const handleAppointmentChange = (event: SelectChangeEvent<string>) => {
    setSelectedAppointment(event.target.value);
  };

  const isFormValid = () => {
    return agentName.trim() !== "" && callbackNumber.trim() !== "" && selectedProvider !== "" && selectedAppointment !== "";
  };

  const handleSubmit = async () => {
    if (!isPhoneNumber(callbackNumber)) {
      setErrorMessage("ERROR: Invalid phone number");
      return;
    }

    posthog?.capture("[PENCILED] Create Agent Button clicked", { providerId: selectedProvider, appointmentTypeId: selectedAppointment });
    const agent: Partial<Agent> = {
      ehr: EHR.healthie,
      enableCalling: true,
      enableTexting: false,
      name: agentName || undefined,
      areaCode: areaCode || undefined,
      ehrProviderId: selectedProvider,
      ehrApptTypeId: selectedAppointment,
      agentType: AgentType.scheduling,
      promptId: EHR.healthie,
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/agents`, { agent: agent }, { headers: { Authorization: `Bearer ${user?.token}` } });

      const userId = user?.userId;
      const token = user?.token;

      if (!token) {
        throw new Error("User token not found");
      }

      dispatch(completeWalkthrough());

      if (userId && token) {
        const action = await dispatch(fetchAgents({ userId, token }));
        if (action.payload instanceof Error) {
          throw new Error(`Failed to fetch agents: ${(action.payload as Error).message}`);
        } else {
          const agents = action.payload?.agents;
          const agentId = agents?.[0]?.agentId;
          if (agentId) {
            posthog?.capture("[PENCILED] Agent created", { agentId: agentId });
            onClose();
          } else {
            navigate(`/dashboard/agents/${agentId}`);
          }
        }
      } else {
        navigate(`/dashboard/agents`);
      }
    } catch (error) {
      console.error("Failed to create agent:", error);
      showMessage("Failed to create agent.", "error");
    }
  };

  return (
    <Box sx={{ overflowY: "auto", height: "100%" }}>
      <ModalBox>
        <Typography variant="h4" fontWeight="bold" marginBottom="30px" sx={{ fontSize: { xs: "1.6rem", sm: "2rem" } }}>
          Create new agent
        </Typography>
        <Typography style={{ marginBottom: "20px", fontFamily: "Konnect-Regular" }}>General Information</Typography>
        <RowBox>
          <FormControl fullWidth>
            <Typography variant="h6" sx={{ marginBottom: { xs: "5px", md: "10px" }, fontSize: { xs: "0.9rem", md: "1.1rem" } }}>
              Agent's Name
            </Typography>
            <OutlinedInput
              placeholder="eg. Chloe"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
              sx={{
                borderRadius: "10px",
                fontSize: { xs: "15px", sm: "16px" },
                backgroundColor: "#ffffff",
              }}
              required
            />
          </FormControl>

          <FormControl fullWidth>
            <Typography variant="h6" sx={{ marginBottom: { xs: "5px", md: "10px" }, fontSize: { xs: "0.95rem", md: "1.1rem" } }}>
              Callback Number
            </Typography>
            <OutlinedInput
              placeholder="eg. +1 000-000-0000"
              value={callbackNumber}
              onChange={(e) => setCallbackNumber(e.target.value)}
              sx={{
                borderRadius: "10px",
                fontSize: { xs: "15px", sm: "16px" },
                backgroundColor: "#ffffff",
              }}
              required
            />

            {errorMessage && (
              <Typography color="error" sx={{ marginTop: "5px" }}>
                {errorMessage}
              </Typography>
            )}
          </FormControl>
        </RowBox>
        <RowBox>
          <FormControl sx={{ width: { xs: "100%", md: "42.5%" } }}>
            <Typography variant="h6" sx={{ marginBottom: { xs: "5px", md: "10px" }, fontSize: { xs: "0.95rem", md: "1.1rem" } }}>
              Desired Area Code
            </Typography>
            <Select
              value={areaCode || "916"}
              onChange={(e: any) => setAreaCode(e.target.value)}
              sx={{
                borderRadius: "10px",
                fontSize: { xs: "15px", sm: "16px" },
                backgroundColor: "#ffffff",
              }}
              required
            >
              <MenuItem value={"916"}>916</MenuItem>
            </Select>
          </FormControl>
        </RowBox>

        {/* {loading && <Typography>Loading...</Typography>} */}
        {loadingProviders && <LoadingWithMessage message="Loading providers..." size={60} />}
        {loadingAppointments && <LoadingWithMessage message="Loading appointments..." size={60} />}
        {loadingPatients && <LoadingWithMessage message="Loading patients..." size={60} />}
        {!loadingProviders && !loadingAppointments && !loadingPatients && providers.length > 0 && (
          <>
            <RowBox>
              <FormControl sx={{ marginTop: "20px", width: { xs: "100%", md: selectedProvider ? "100%" : "42.5%" } }}>
                <InputLabel id="select-provider-label">Provider Name</InputLabel>
                <Select
                  labelId="select-provider-label"
                  id="select-provider"
                  value={selectedProvider}
                  label="Provider Name"
                  onChange={handleProviderChange}
                >
                  {providers.map((provider) => (
                    <MenuItem key={provider.id} value={provider.id}>
                      {provider.firstName} {provider.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {selectedProvider && appointments.length > 0 && (
                <FormControl fullWidth sx={{ marginTop: "20px" }}>
                  <InputLabel id="select-appointment-label">Appointment Type</InputLabel>
                  <Select
                    labelId="select-appointment-label"
                    id="select-appointment"
                    value={selectedAppointment}
                    label="Appointment Type"
                    onChange={handleAppointmentChange}
                  >
                    {appointments.map((appointment) => (
                      <MenuItem key={appointment.id} value={appointment.id}>
                        {appointment.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </RowBox>
          </>
        )}

        <div style={{ marginTop: "20px", display: "flex", justifyContent: "right" }}>
          <PrimaryButton onClick={handleSubmit} disabled={!isFormValid()}>
            Create Agent
          </PrimaryButton>

          <TransparentButton onClick={onClose} variant="outlined" color="primary" fullWidth={false} style={{ marginLeft: "10px" }}>
            Cancel
          </TransparentButton>
        </div>
      </ModalBox>
    </Box>
  );
};

export default CreateAgentModal;
