import React, { FocusEventHandler, useCallback, useEffect, useState } from "react";
import { Modal, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton, Icon, TextField } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";

const Debug: React.FC = () => {
  const makeApiRequest = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/`);
    const data = await response.json();
    console.log(data);
  };

  return <button onClick={makeApiRequest}>Make API Request</button>;
};

export default Debug;
