import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  Input,
  Menu,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  SelectChangeEvent,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import { Form, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import moment from "moment-timezone";
import { DateTime } from "luxon";
import { DatePicker } from "@mui/x-date-pickers";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatIsoToCustomDateStringWithEEEE } from "../utils/utils";
import { EHR, FrontendUser, Organization, RawHealthiePatient } from "../types";
import { PrimaryButton, SecondaryButton } from "../subcomponents/CustomButton";
import { HelpTooltip } from "../subcomponents/HelpTooltip";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import CloseIcon from "@mui/icons-material/Close";
import CircleIcon from "@mui/icons-material/Circle";
import { Colors } from "../Colors";
import { updatePatient } from "../slices/PatientSlice";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import CustomDatePicker from "../components/date-picker/DatePicker";
import Footer from "../subcomponents/Footer";
import { FooterBox, HeaderBox } from "../subcomponents/SidebarComponents";

const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  margin: 10% auto;
  background-color: #fff;
  padding: 2%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    width: 70%;
    padding: 5%;
  }
`;

const ModalScrollBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 10% auto;
  background-color: #fff;
  padding: 2%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-height: 70%;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 70%;
    padding: 5%;
  }
`;

const timezones = moment.tz.names();

const timezoneOptions = timezones.map((tz) => {
  const dt = DateTime.now().setZone(tz);
  const offsetString = dt.toFormat("ZZ");
  const text = `${tz} (UTC${offsetString})`;

  return { value: tz, label: text, offset: dt.offset };
});

timezoneOptions.sort((a, b) => a.offset - b.offset);

const contactOptions = [
  { value: "call", label: "Call" },
  { value: "text", label: "Text" },
];

const languageOptions = [
  { label: "English", value: "en" },
  /*
  { label: "Spanish", value: "es" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "Chinese", value: "zh" },
   */
  // TODO (Hizami): Figure out what languages we want
];

type UpdatePatientModalProps = {
  patient: any;
  open: boolean;
  onClose: () => void;
};

const UpdatePatientModal: React.FC<UpdatePatientModalProps> = ({ patient, open, onClose }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const healthiePatients = useSelector((state: RootState) => state.healthieIntegration.patients);
  const { agentId } = useParams<{ agentId: string }>();
  const [timezone, setTimezone] = React.useState<string>(patient.timezone);
  const [language, setLanguage] = React.useState<string>(patient.language);

  const [textEnabled, setTextEnabled] = React.useState<boolean>(patient.textEnabled);
  const [callEnabled, setCallEnabled] = React.useState<boolean>(patient.callEnabled);

  const [availableDates, setAvailableDates] = React.useState<Date[]>(patient?.availableDates.map((date: string) => new Date(date))); // Using Date instead of DateTime for compatibility with CustomDatePicker
  const [selectedProviders, setSelectedProviders] = React.useState<string[]>(patient?.providerIds);
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = React.useState<string[]>(patient?.appointmentTypeIds);
  const [selectedContactTypes, setSelectedContactTypes] = React.useState<string[]>(patient?.contactTypes);

  const loadingAppointents = useSelector((state: RootState) => state.healthieIntegration.loadingAppointments);
  const loadingProviders = useSelector((state: RootState) => state.healthieIntegration.loadingProviders);
  const loadingHealthiePatients = useSelector((state: RootState) => state.healthieIntegration.loadingPatients);

  const loadingFetchWaitlistPatients: boolean = useSelector((state: RootState) => state.patients.loadingFetchPatients);
  const loadingDeletePatient: boolean = useSelector((state: RootState) => state.patients.loadingDeletePatient);
  const loadingUpdatePatient: boolean = useSelector((state: RootState) => state.patients.loadingUpdatePatient);
  const loadingUpdatePriorities: boolean = useSelector((state: RootState) => state.patients.loadingUpdatePriorities);
  const loadingCreatePatient: boolean = useSelector((state: RootState) => state.patients.loadingCreatePatient);
  const loadingGetPatient: boolean = useSelector((state: RootState) => state.patients.loadingGetPatient);

  const dispatch = useAppDispatch();
  const providers = useSelector((state: RootState) => state.healthieIntegration.providers);
  const appointments = useSelector((state: RootState) => state.healthieIntegration.appointments);
  const contactTypes = ["In Person", "Healthie Video Call", "Phone Call"]; // TODO (Hizami): This is hard-coded from healthie

  const [patientGroupId, setPatientGroupId] = React.useState<string | undefined>(patient.patientGroupId || undefined);
  const patientGroupOptions = [
    { value: "", label: "None" },
    ...(organization?.patientGroups?.map((group) => ({
      value: group.patientGroupId,
      label: group.name,
    })) || []),
  ];

  const handleContactModeChange = (mode: "text" | "call" | "both") => {
    setTextEnabled(mode === "text" || mode === "both");
    setCallEnabled(mode === "call" || mode === "both");
  };

  const handleSelectAllProviders = () => {
    setSelectedProviders(providers.map((provider) => provider.id));
  };

  const handleDeselectAllProviders = () => {
    setSelectedProviders([]);
  };

  const handleSelectAllAppointmentTypes = () => {
    setSelectedAppointmentTypes(appointments.map((appointment) => appointment.id));
  };

  const handleDeselectAllAppointmentTypes = () => {
    setSelectedAppointmentTypes([]);
  };

  const handleSelectAllContactTypes = () => {
    setSelectedContactTypes(contactTypes);
  };

  const handleDeselectAllContactTypes = () => {
    setSelectedContactTypes([]);
  };

  useEffect(() => {
    if (patient?.providerIds) {
      setSelectedProviders(patient.providerIds);
    }
  }, [patient?.providerIds]);

  useEffect(() => {
    if (patient?.appointmentTypeIds) {
      setSelectedAppointmentTypes(patient?.appointmentTypeIds);
    }
  }, [patient?.appointmentTypeIds]);

  useEffect(() => {
    if (patient?.contactTypes) {
      setSelectedContactTypes(patient.contactTypes);
    }
  }, [patient?.contactTypes]);

  const handleRemoveDate = (index: number) => {
    setAvailableDates((prevDates) => prevDates.filter((_, idx) => idx !== index));
  };

  const providerOptions = providers.map((provider) => ({
    value: provider.id,
    label: provider.firstName + " " + provider.lastName,
  }));

  const appointmentOptions = appointments.map((appointment) => ({
    value: appointment.id,
    label: appointment.name,
  }));

  const contactTypeOptions = contactTypes.map((contactType) => ({
    value: contactType,
    label: contactType,
  }));

  return (
    // <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        overflowY: "auto",
        height: "92%",
        paddingBottom: "40px",
        paddingRight: "20px",
      }}
    >
      <HeaderBox title={"Update Patient"} onClose={onClose} />

      <Typography variant="body1" marginTop={"30px"}>
        Patient
      </Typography>
      <Box
        sx={{
          padding: "10px",
          background: Colors.textfield,
          borderRadius: "10px",
          color: "#FFFFFF",
          marginTop: "5px",
          position: "relative",
          "&:hover .close-icon": {
            visibility: "visible",
          },
        }}
      >
        <Typography variant="body1" fontWeight={"bold"} fontSize={"1rem"}>
          {`${patient?.firstName ?? ""} ${patient?.lastName ?? ""}`}
        </Typography>
        <Box color={Colors.info} display="flex" alignItems="center" gap="10px" mt={0.5}>
          <Typography variant="body2">{`${patient?.phoneNumber ?? ""}`}</Typography>
          <CircleIcon sx={{ fontSize: "5px" }} />
          <Typography variant="body2">{`ID: ${patient?.healthiePatientId ?? ""}`}</Typography>
          <CircleIcon sx={{ fontSize: "5px" }} />
          <Typography variant="body2">{`${patient?.timezone ?? ""}`}</Typography>
        </Box>
      </Box>

      <Typography variant="body1" mt={3} mb={1}>
        AI Agent Mode of Contact
      </Typography>
      <FormControl variant="outlined" fullWidth>
        <Box display="flex" gap="10px">
          <Button
            onClick={() => handleContactModeChange("call")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: callEnabled && !textEnabled ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: callEnabled && !textEnabled ? "#FFFFFF" : Colors.info,
            }}
          >
            Call Only
          </Button>
          <Button
            onClick={() => handleContactModeChange("text")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: textEnabled && !callEnabled ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: textEnabled && !callEnabled ? "#FFFFFF" : Colors.info,
            }}
          >
            Text Only
          </Button>
          <Button
            onClick={() => handleContactModeChange("both")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: textEnabled && callEnabled ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: textEnabled && callEnabled ? "#FFFFFF" : Colors.info,
            }}
          >
            Both
          </Button>
        </Box>
      </FormControl>

      <Typography variant="body1" fontWeight="bold" mt={3}>
        Waitlist Parameters <HelpTooltip title="The patient will automatically be put on waitlists for appointments fitting these criteria." />
      </Typography>

      {patientGroupOptions.length > 0 && (
        <FormControl fullWidth>
          <Box display="flex" alignItems="center" gap="10px" mt={2}>
            <Typography variant="body1">Priority Group</Typography>
          </Box>
          <Select
            value={patientGroupId}
            onChange={(e) => setPatientGroupId(e.target.value)}
            sx={{
              borderRadius: "10px",
              background: Colors.textfield,
              marginTop: "5px",
              "& .MuiInputBase-input": {
                padding: "15px",
                fontSize: "0.9rem",
                color: "#FFFFFF",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            }}
          >
            {patientGroupOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {loadingProviders && <LoadingWithMessage message="Loading providers..." size={30} />}
      {!loadingProviders && (
        <FormControl fullWidth>
          <Box display="flex" alignItems="center" gap="10px">
            <Typography variant="body1">Providers</Typography>
            {selectedProviders?.length === providerOptions?.length ? (
              <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllProviders}>
                Deselect All
              </Button>
            ) : (
              <Button sx={{ textTransform: "none" }} onClick={handleSelectAllProviders}>
                Select All
              </Button>
            )}
          </Box>
          <Select
            multiple
            displayEmpty
            value={selectedProviders}
            onChange={(e) => setSelectedProviders(e.target.value as string[])}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <Typography sx={{ color: Colors.info }}>Select options</Typography>;
              }
              return selected.map((value) => providerOptions.find((option) => option.value === value)?.label).join(", ");
            }}
            sx={{
              borderRadius: "10px",
              background: Colors.textfield,
              marginTop: "5px",
              "& .MuiInputBase-input": {
                padding: "15px",
                fontSize: "0.9rem",
                color: "#FFFFFF",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            }}
          >
            {providerOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={selectedProviders.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {!loadingProviders && providers.length === 0 && (
        <Typography variant="body2" color="error" mt={1}>
          No providers found. Please check Healthie.
        </Typography>
      )}

      {loadingAppointents && <LoadingWithMessage message="Loading appointment types..." size={30} />}
      {!loadingAppointents && (
        <FormControl fullWidth>
          <Box display="flex" alignItems="center" gap="10px" mt={2}>
            <Typography variant="body1">Appointment Types</Typography>
            {selectedAppointmentTypes?.length === appointmentOptions?.length ? (
              <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllAppointmentTypes}>
                Deselect All
              </Button>
            ) : (
              <Button sx={{ textTransform: "none" }} onClick={handleSelectAllAppointmentTypes}>
                Select All
              </Button>
            )}
          </Box>
          <Select
            multiple
            displayEmpty
            value={selectedAppointmentTypes}
            onChange={(e) => setSelectedAppointmentTypes(e.target.value as string[])}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <Typography sx={{ color: Colors.info }}>Select options</Typography>;
              }
              return selected.map((value) => appointmentOptions?.find((option) => option.value === value)?.label).join(", ");
            }}
            sx={{
              borderRadius: "10px",
              background: Colors.textfield,
              marginTop: "5px",
              "& .MuiInputBase-input": {
                padding: "15px",
                fontSize: "0.9rem",
                color: "#FFFFFF",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            }}
          >
            {appointmentOptions &&
              appointmentOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={selectedAppointmentTypes.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      {!loadingAppointents && appointments.length === 0 && (
        <Typography variant="body2" color="error" mt={1}>
          No appointment types found. Please check Healthie.
        </Typography>
      )}

      <FormControl fullWidth>
        <Box display="flex" alignItems="center" gap="10px" mt={2}>
          <Typography variant="body1">Contact Types</Typography>
          {selectedContactTypes?.length === contactTypeOptions?.length ? (
            <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllContactTypes}>
              Deselect All
            </Button>
          ) : (
            <Button sx={{ textTransform: "none" }} onClick={handleSelectAllContactTypes}>
              Select All
            </Button>
          )}
        </Box>
        <Select
          multiple
          displayEmpty
          value={selectedContactTypes}
          onChange={(e) => setSelectedContactTypes(e.target.value as string[])}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <Typography sx={{ color: Colors.info }}>Select options</Typography>;
            }
            return selected.join(", ");
          }}
          sx={{
            borderRadius: "10px",
            background: Colors.textfield,
            marginTop: "5px",
            "& .MuiInputBase-input": {
              padding: "15px",
              fontSize: "0.9rem",
              color: "#FFFFFF",
            },
            "& .MuiSvgIcon-root": {
              color: "#FFF",
            },
          }}
        >
          {contactTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={selectedContactTypes.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography variant="body1" mt={2} mb={1}>
        Available Days
      </Typography>

      <CustomDatePicker dates={availableDates} onDatesChange={setAvailableDates} placeholder="Select dates" />

      {availableDates.length > 0 && (
        <List
          sx={{
            width: "100%",
            bgcolor: "#1A1E36",
            display: "flex",
            gap: "8px",
            flexWrap: "wrap",
            marginTop: "10px",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          {availableDates.map((date, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" aria-label="delete" style={{ color: Colors.info }} onClick={() => handleRemoveDate(index)}>
                  <CloseIcon />
                </IconButton>
              }
              sx={{
                background: "#23283E",
                color: "#FFF",
                margin: "2px 0",
                paddingTop: "2px",
                paddingBottom: "2px",
                width: "auto",
                borderRadius: "10px",
                minHeight: "unset",
                fontSize: "0.75rem",
              }}
            >
              <ListItemText
                primary={formatIsoToCustomDateStringWithEEEE(date.toISOString())}
                sx={{
                  fontSize: "0.75rem",
                  lineHeight: "1.2",
                }}
              />
            </ListItem>
          ))}
        </List>
      )}

      <FooterBox
        onClick={async () => {
          if (!user?.token) {
            return;
          }
          if (patient?.patientId) {
            await dispatch(
              updatePatient({
                token: user.token,
                patientId: patient.patientId,
                params: {
                  organizationId: user?.organizationId,
                  timezone,
                  language,
                  textEnabled,
                  callEnabled,
                  availableDates: availableDates,
                  providerIds: selectedProviders,
                  appointmentTypeIds: selectedAppointmentTypes,
                  contactTypes: selectedContactTypes,
                  patientGroupId,
                },
              })
            );
            onClose();
          }
        }}
        buttonText={"Update Patient"}
        loading={loadingUpdatePatient}
      />
    </Box>
  );
};

export default UpdatePatientModal;
