import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { EHR, FrontendUser, HealthieAppointment, RawHealthiePatient, HealthieProvider } from "../types";
import { sessionExpired } from "./SessionSlice";
import { getSession } from "./AuthSlice";
import { RootState } from "../store";
import { api } from "../utils/utils";

export interface HealthieIntegrationState {
  providers: HealthieProvider[];
  appointments: HealthieAppointment[];
  patients: RawHealthiePatient[];
  error: string | null;
  loadingUploadingToken: boolean;
  loadingDeletingIntegration: boolean;
  loadingProviders: boolean;
  loadingAppointments: boolean;
  loadingPatients: boolean;
}

const initialState: HealthieIntegrationState = {
  providers: [],
  appointments: [],
  patients: [],
  error: null,
  loadingUploadingToken: false,
  loadingDeletingIntegration: false,
  loadingProviders: false,
  loadingAppointments: false,
  loadingPatients: false,
};

export const fetchProviders = createAsyncThunk<{ providers: HealthieProvider[] }, { token: string }, { rejectValue: Error }>(
  "integrations/fetchProviders",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/integrations/healthie/providers`, token);

      return { providers: response.data };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch providers: " + (error as Error).message));
    }
  }
);

export const fetchAppointments = createAsyncThunk<
  { appointments: HealthieAppointment[] },
  { token: string; providerIds: string[] },
  { rejectValue: Error }
>("integrations/fetchAppointments", async ({ token, providerIds }, { rejectWithValue }) => {
  try {
    if (providerIds.length > 0) {
      const providerId = providerIds[0];
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/integrations/healthie/appointmentTypes?providerId=${providerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { appointments: response.data as HealthieAppointment[] };
    } else {
      return { appointments: [] };
    }
  } catch (error) {
    if ((error as AxiosError).response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch appointments: " + (error as Error).message));
  }
});

export const fetchHealthiePatients = createAsyncThunk<{ patients: RawHealthiePatient[] }, { token: string }, { rejectValue: Error }>(
  "/integrations/fetchPatients",
  async ({ token }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/integrations/healthie/patients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return { patients: response.data };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch patients: " + (error as Error).message));
    }
  }
);

export const handleTokenUpload = createAsyncThunk<void, { token: string; ehrToken: string; ehr: EHR }, { rejectValue: Error }>(
  "integrations/handleTokenUpload",
  async ({ token, ehrToken, ehr }, { dispatch, rejectWithValue }) => {
    try {
      if (!ehrToken) {
        console.error("Token is empty");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/integrations/${ehr}`,
        { ehrToken: ehrToken },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        dispatch(getSession());
      }
    } catch (error) {
      console.error(`Error updating ${ehr} token:`, error);
      return rejectWithValue(new Error(`Error updating ${ehr} token: ${error}`));
    }
  }
);

export const handleDeleteIntegration = createAsyncThunk<void, { token: string; ehr: EHR }, { rejectValue: Error }>(
  "integrations/handleDeleteIntegration",
  async ({ token, ehr }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/integrations/${ehr}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        dispatch(clearIntegration());
        dispatch(getSession());
      }
    } catch (error) {
      console.error(`Error disconnecting from ${ehr}:`, error);
      return rejectWithValue(new Error(`Error disconnecting from ${ehr}: ${error}`));
    }
  }
);

const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    clearIntegration: (state) => {
      state.providers = [];
      state.appointments = [];
      state.error = null;
      state.loadingProviders = false;
      state.loadingAppointments = false;
      state.loadingPatients = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviders.pending, (state) => {
        state.loadingProviders = true;
      })
      .addCase(fetchProviders.fulfilled, (state, action) => {
        state.providers = action.payload.providers;
        state.loadingProviders = false;
      })
      .addCase(fetchProviders.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch providers";
        state.loadingProviders = false;
      })
      .addCase(fetchAppointments.pending, (state) => {
        state.loadingAppointments = true;
      })
      .addCase(fetchAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload.appointments;
        state.loadingAppointments = false;
      })
      .addCase(fetchAppointments.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch appointments";
        state.loadingAppointments = false;
      })
      .addCase(fetchHealthiePatients.pending, (state) => {
        state.loadingPatients = true;
      })
      .addCase(fetchHealthiePatients.fulfilled, (state, action) => {
        state.patients = action.payload.patients;
        state.loadingPatients = false;
      })
      .addCase(fetchHealthiePatients.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch patients";
        state.loadingPatients = false;
      })
      .addCase(handleTokenUpload.pending, (state) => {
        state.loadingUploadingToken = true;
      })
      .addCase(handleTokenUpload.fulfilled, (state) => {
        state.loadingUploadingToken = false;
      })
      .addCase(handleTokenUpload.rejected, (state, action) => {
        state.error = action.error.message || "Failed to upload token";
        state.loadingUploadingToken = false;
      })
      .addCase(handleDeleteIntegration.pending, (state) => {
        state.loadingDeletingIntegration = true;
      })
      .addCase(handleDeleteIntegration.fulfilled, (state) => {
        state.loadingDeletingIntegration = false;
      })
      .addCase(handleDeleteIntegration.rejected, (state, action) => {
        state.error = action.error.message || "Failed to delete integration";
        state.loadingDeletingIntegration = false;
      });
  },
});

export const { clearIntegration } = integrationsSlice.actions;

export default integrationsSlice.reducer;
