import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, MenuItem, Select, Typography, TextField, Box, IconButton, InputAdornment, CircularProgress } from "@mui/material";
import axios from "axios";
import Loader from "../../subcomponents/Loader";
import AgentCard from "../cards/AgentCard";
import { RootState, useAppDispatch } from "../../store";
import { AgentTemplate, FrontendUser, EHR, Agent, Organization } from "../../types";
import { PrimaryButton } from "../../subcomponents/CustomButton";
import LoadingWithMessage from "../../subcomponents/LoadingWithMessage";
import { RowBox } from "../../styles";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Colors } from "../../Colors";
import { DEFAULT_DEVELOPMENT_PHONE_NUMBER } from "../../utils/constants";
import { encryptTokenWithPublicKey } from "../../utils/utils";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { setAgentSetupCompleted, setAgentSetupPartial } from "../../slices/AuthSlice";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { createAgent } from "../../slices/AgentSlice";
import { handleTokenUpload } from "../../slices/HealthieIntegrationSlice";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { fi } from "date-fns/locale";
import Footer from "../../subcomponents/Footer";
import { FooterBox } from "../../subcomponents/SidebarComponents";
import SelectComponent from "../../subcomponents/SelectComponent";

const defaultAreaCode = "415";

enum AgentCreationStep {
  SelectAgent = 0,
  CustomizeAgent = 1,
}

interface AgentCardSelectionsProps {
  selectedAgentTemplate: AgentTemplate | null;
  setSelectedAgentTemplate: (template: AgentTemplate) => void;
  onClick: () => void;
  onComplete: () => void;
  onClose: () => void;
}

const AgentCardSelections: React.FC<AgentCardSelectionsProps> = ({
  selectedAgentTemplate,
  setSelectedAgentTemplate,
  onClick,
  onComplete,
  onClose,
}) => {
  const loadingTemplates: boolean = useSelector((state: RootState) => state.agentTemplates.loading);
  const loadingTemplatesMsg: string = useSelector((state: RootState) => state.agentTemplates.loadingMsg) || "Loading...";
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const [areaCode, setAreaCode] = useState<string>(defaultAreaCode);
  const [availableNumbers, setAvailableNumbers] = useState<{ friendlyName: string; phoneNumber: string }[]>([]);
  const [loadingFetchNumbers, setLoadingFetchNumbers] = useState<boolean>(false);
  const [loadingBuyNumber, setLoadingBuyNumber] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [purchasedNumber, setPurchasedNumber] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [ehrSelection, setEhrSelection] = useState<EHR | null>(null);
  const [apiKey, setApiKey] = useState<string>("");
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [apiKeySubmitted, setApiKeySubmitted] = useState<boolean>(false);
  const [showApiKeyInput, setShowApiKeyInput] = useState<boolean>(false);
  const [showRequestApiKey, setShowRequestApiKey] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(AgentCreationStep.SelectAgent);
  const [ehrProviderId, setEhrProviderId] = useState<string>("");
  const [ehrAppointmentTypeId, setEhrAppointmentTypeId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const [sendRequestLoading, setSendRequestLoading] = useState<boolean>(false);
  const [saveProgressLoading, setSaveProgressLoading] = useState<boolean>(false);
  const [agentCreateLoading, setAgentCreateLoading] = useState<boolean>(false);

  const agentTemplates: AgentTemplate[] = useSelector((state: RootState) => state.agentTemplates.templates);

  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();

  // const handleClick = async () => {
  //   if (!selectedAgentTemplate || !phoneNumber || !ehrSelection) return;

  //   onClick();
  //   if (ehrSelection === EHR.healthie && showApiKeyInput && apiKey) {
  //     await handleCreateAgentWithEhrParameters(ehrProviderId, ehrAppointmentTypeId);
  //   } else {
  //     await handleCreateAgentWithoutEhrParameters();
  //   }
  // };

  const handleClick = async () => {
    if (!user?.token || !selectedAgentTemplate || !phoneNumber || !ehrSelection) return;

    setAgentCreateLoading(true);
    const agent: Partial<Agent> = {
      agentType: selectedAgentTemplate?.type,
      enableCalling: true,
      enableTexting: false,
      name: selectedAgentTemplate?.name,
      areaCode,
      promptId: selectedAgentTemplate?.promptId,
      phoneNumber,
      ehr: ehrSelection,
      ehrProviderId: ehrProviderId || undefined,
      ehrApptTypeId: ehrAppointmentTypeId || undefined,
    };

    onClick();
    showMessage(
      `Agent customized successfully", "Your waitlist AI agent- ${agent.name}, is ready to start rescuing last minute cancellations!`,
      "success"
    );

    try {
      await dispatch(createAgent({ token: user.token, agent }));
      onComplete();
    } catch (error) {
      setErrorMessage("Failed to create agent.");
    } finally {
      setAgentCreateLoading(false);
      setLoadingMessage("");
    }
  };

  const handleSaveProgress = async () => {
    setSaveProgressLoading(true);
    const agent: Partial<Agent> = {
      ehr: EHR.healthie,
      agentType: selectedAgentTemplate?.type,
      enableCalling: false,
      enableTexting: false,
      name: selectedAgentTemplate?.name,
      areaCode,
      promptId: selectedAgentTemplate?.promptId,
      phoneNumber,
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/agents`, { agent }, { headers: { Authorization: `Bearer ${user?.token}` } });
      // Save user inputs for future use
      dispatch(setAgentSetupPartial(true));
      // localStorage.setItem(
      //   'agentSetupData',
      //   JSON.stringify({
      //     areaCode,
      //     phoneNumber,
      //     ehrSelection,
      //     showRequestApiKey: true,
      //   })
      // );
      onClose();
      showMessage("Progress saved successfully", "", "success");
    } catch (error) {
      console.error("Error saving progress:", error);
    } finally {
      setSaveProgressLoading(false);
    }
  };

  const handleEHRSelection = (value: EHR) => {
    setEhrSelection(value);
    if (value === EHR.healthie) {
      setShowApiKeyInput(true);
      setShowRequestApiKey(false);
    } else {
      setShowApiKeyInput(false);
      setShowRequestApiKey(false);
    }
  };

  const handleApiKeyOptionChange = (hasApiKey: boolean) => {
    setShowApiKeyInput(hasApiKey);
    setShowRequestApiKey(!hasApiKey);
  };

  // Handle API Key Submit
  const handleApiKeySubmit = async () => {
    setApiLoading(true);
    try {
      if (!apiKey) {
        console.error("API key is empty");
        return;
      }

      // console.log(user?.token);
      if (!user?.token) {
        return;
      }

      // Use the handleTokenUpload thunk from your IntegrationSlice
      await dispatch(handleTokenUpload({ token: user.token, ehrToken: apiKey, ehr: EHR.healthie }));

      setApiKey("●●●●●●●●");
      setShowRequestApiKey(false);
      dispatch(setAgentSetupCompleted(true));
      setApiKeySubmitted(true);
      // onComplete();
    } catch (error) {
      console.error(`Error updating Healthie API key:`, error);
      setErrorMessage(`Failed to upload Healthie API key. Please refresh the page and try again.`);
    } finally {
      setApiLoading(false);
    }
  };

  const handleCreateAgentWithEhrParameters = async (providerId: string, appointmentTypeId: string) => {
    setLoading(true);
    setLoadingMessage("Creating agent...");
    const agent: Partial<Agent> = {
      ehr: EHR.healthie,
      enableCalling: true,
      enableTexting: false,
      name: selectedAgentTemplate?.name,
      areaCode,
      ehrProviderId: providerId,
      ehrApptTypeId: appointmentTypeId,
      agentType: selectedAgentTemplate?.type,
      promptId: selectedAgentTemplate?.promptId,
      phoneNumber,
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/agents`, { agent }, { headers: { Authorization: `Bearer ${user?.token}` } });
      onComplete();
    } catch (error) {
      setErrorMessage("Failed to create agent.");
    } finally {
      setLoading(false);
      setLoadingMessage("");
    }
  };

  const handleCreateAgentWithoutEhrParameters = async () => {
    setLoading(true);
    setLoadingMessage("Creating agent...");
    const agent: Partial<Agent> = {
      agentType: selectedAgentTemplate?.type,
      enableCalling: true,
      enableTexting: false,
      name: selectedAgentTemplate?.name,
      areaCode,
      promptId: selectedAgentTemplate?.promptId,
      phoneNumber,
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/agents`, { agent }, { headers: { Authorization: `Bearer ${user?.token}` } });
      onComplete();
    } catch (error) {
      setErrorMessage("Failed to create agent.");
    } finally {
      setLoading(false);
      setLoadingMessage("");
    }
  };

  // Function to get the cookies and authenticity token
  const getCookieAndToken = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/healthie-form/cookie-and-token`, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error("Error retrieving cookies and authenticity token:", error);
      throw error;
    }
  };

  // Function to submit the form
  const submitHealthieForm = async (
    organizationName: string,
    firstName: string,
    lastName: string,
    email: string,
    authenticityToken: string,
    cookies: string[]
  ) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/healthie-form/submit-form`,
        {
          organizationName,
          firstName,
          lastName,
          email,
          authenticityToken,
          cookies,
        },
        { withCredentials: true }
      );
    } catch (error) {
      console.error("Error submitting Healthie form:", error);
    }
  };

  // Main function to handle the form submission
  const handleApiKeyResponse = async () => {
    setSendRequestLoading(true);
    const { cookie, authenticityToken } = await getCookieAndToken();
    try {
      await submitHealthieForm(organization?.name || "", user?.firstName || "", user?.lastName || "", user?.email || "", authenticityToken, cookie);
      setRequestSent(true);
    } catch (error) {
      console.error("Error during form submission process:", error);
    } finally {
      setSendRequestLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAgentTemplate) {
      setCurrentStep(AgentCreationStep.CustomizeAgent);
    }
  }, [selectedAgentTemplate]);

  // useEffect(() => {
  //   const savedData = localStorage.getItem('agentSetupData');
  //   if (savedData) {
  //     const parsedData = JSON.parse(savedData);
  //     setAreaCode(parsedData.areaCode || defaultAreaCode);
  //     setPhoneNumber(parsedData.phoneNumber || '');
  //     setEhrSelection(parsedData.ehrSelection || null);
  //     setShowRequestApiKey(parsedData.showRequestApiKey || false);
  //   }
  // }, []);

  useEffect(() => {
    const fetchAvailableNumbers = async () => {
      setLoadingFetchNumbers(true);
      try {
        if (areaCode) {
          const fetchedNumbers = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/phone-numbers?areaCode=${areaCode}`, {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          });
          if (fetchedNumbers.data) {
            setAvailableNumbers(fetchedNumbers.data);
          }
        }
      } catch (error) {
        setErrorMessage((error as any)?.response?.data?.error || (error as Error).message || "");
      } finally {
        setLoadingFetchNumbers(false);
      }
    };

    fetchAvailableNumbers();
  }, [areaCode]);

  useEffect(() => {
    const buyNumber = async () => {
      setLoadingBuyNumber(true);
      try {
        if (phoneNumber && phoneNumber !== DEFAULT_DEVELOPMENT_PHONE_NUMBER) {
          if (process.env.REACT_APP_ENV !== "development") {
            const buyNumberRequest = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/api/phone-numbers/buy`,
              {
                phoneNumber: phoneNumber,
              },
              {
                headers: {
                  Authorization: `Bearer ${user?.token}`,
                },
              }
            );
            if (buyNumberRequest.status === 200) {
              setPurchasedNumber(true);
            }
          } else {
            // development
            setPhoneNumber(DEFAULT_DEVELOPMENT_PHONE_NUMBER);
            setPurchasedNumber(true);
          }
        }
      } catch (error) {
        setErrorMessage((error as any)?.response?.data?.error || (error as Error).message || "");
      } finally {
        setLoadingBuyNumber(false);
      }
    };

    buyNumber();
  }, [phoneNumber]);

  const handleContinue = () => {
    setCurrentStep(AgentCreationStep.CustomizeAgent);
  };

  const isAgentCreationReady = selectedAgentTemplate && phoneNumber && (!showApiKeyInput || apiKeySubmitted);

  return (
    <>
      <Box
        sx={{
          overflowY: "auto",
          height: "92%",
          paddingBottom: "20px",
          paddingRight: "20px",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            padding: "12px 25px",
            width: "100%",
            display: "flex",
            borderBottom: "2px solid Colors.textfield",
            background: "#151A33",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <Box display={"flex"} gap={"10px"}>
            {currentStep === AgentCreationStep.CustomizeAgent && (
              <ChevronLeft onClick={() => setCurrentStep(AgentCreationStep.SelectAgent)} sx={{ color: "#FFFFFF", cursor: "pointer" }} />
            )}
            <Typography variant={"body2"} fontSize={"1.1rem"} fontWeight={"bold"}>
              Create agent
            </Typography>
          </Box>
          <IconButton onClick={onClose} sx={{ color: "#FFFFFF" }}>
            <CloseIcon />
          </IconButton>
        </Box>

        {currentStep === AgentCreationStep.SelectAgent && (
          <>
            <Typography variant="body1" fontSize={"1.1rem"} margin={"30px 0 15px 0"}>
              Meet Wendy, your waitlist agent
            </Typography>
            {loadingTemplates && <Loader message={loadingTemplatesMsg} size={50} />}
            {!loadingTemplates && agentTemplates.length > 0 && (
              <>
                {agentTemplates
                  .filter((template: AgentTemplate) => template.active)
                  .map((template: AgentTemplate) => (
                    <AgentCard
                      key={template.templateId}
                      onClick={() => {
                        setSelectedAgentTemplate(template);
                      }}
                      agentTemplate={template}
                    />
                  ))}
              </>
            )}

            {!loadingTemplates && agentTemplates.length === 0 && (
              <Typography variant="h4" style={{ textAlign: "center", marginTop: "50px" }}>
                No agent templates found. Please try again later.
              </Typography>
            )}
          </>
        )}

        {currentStep === AgentCreationStep.CustomizeAgent && (
          <>
            <Typography variant="body1" fontSize={"1.1rem"} margin={"30px 0"}>
              Customize AI agent
            </Typography>

            {areaCode && (
              <SelectComponent
                label="Which area code would you like Sandy to call from?"
                placeholder="Select area code"
                value={areaCode || defaultAreaCode}
                onChange={(e: any) => setAreaCode(e.target.value)}
                options={[
                  { value: "916", label: "916" },
                  { value: defaultAreaCode, label: defaultAreaCode },
                  { value: "646", label: "646" },
                ]}
                required
              />
            )}

            {areaCode && loadingFetchNumbers && <LoadingWithMessage message="Fetching available numbers..." size={20} />}

            {areaCode && !loadingFetchNumbers && availableNumbers.length > 0 && (
              <SelectComponent
                label="Which phone number would you like Wendy to call from?"
                placeholder="Select phone number"
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value as string)} // No need for `e.target.value` here, as `value` is passed directly
                options={availableNumbers.map((num) => ({
                  value: num.phoneNumber,
                  label: num.friendlyName,
                }))}
                required
              />
            )}

            <RowBox>
              <SelectComponent
                label="Connect an EHR"
                placeholder="Select an EHR"
                value={ehrSelection || ""}
                onChange={(value) => handleEHRSelection(value as EHR)}
                options={[
                  { value: EHR.none, label: "None" },
                  { value: EHR.healthie, label: "Healthie" },
                ]}
                required
              />
            </RowBox>

            {ehrSelection === EHR.healthie && (
              <Box sx={{ background: Colors.bg2, marginTop: "15px", padding: "15px", borderRadius: "10px" }}>
                <SelectComponent
                  label="Health API key is required to scan your calendar and book patients directly into Healthie."
                  value={showApiKeyInput ? "have-key" : showRequestApiKey ? "no-key" : ""}
                  onChange={(value) => handleApiKeyOptionChange(value === "have-key")}
                  options={[
                    { value: "have-key", label: "I have a Healthie API key" },
                    { value: "no-key", label: "I don't have a Healthie API key" },
                  ]}
                  required
                />

                <FormControl fullWidth>
                  {showApiKeyInput && (
                    <TextField
                      placeholder="Enter your Healthie API key"
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                      sx={{
                        marginTop: "10px",
                        fontSize: { xs: "15px", sm: "16px" },
                        background: Colors.textfield,
                        border: "none",
                        borderRadius: "10px",
                        "& .MuiInputBase-input": {
                          padding: "13px",
                          fontSize: "0.85rem",
                          color: "#FFFFFF",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ position: "absolute", top: "50%", right: 0 }}>
                            <IconButton
                              onClick={handleApiKeySubmit}
                              sx={{ background: Colors.primary, borderRadius: 1, ":hover": { background: Colors.primaryDark } }}
                            >
                              {apiLoading ? <CircularProgress size={20} color={"inherit"} /> : <ArrowForwardIcon sx={{ color: Colors.secondary }} />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}

                  <a href="https://penciled.com/blogs/how-to-get-healthie-api-key" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" mt={1} color="primary">
                      How do I get a Healthie API key?
                    </Typography>
                  </a>
                </FormControl>

                {showRequestApiKey && (
                  <>
                    {requestSent ? (
                      <Box sx={{ marginTop: "20px", background: Colors.bg3, borderRadius: "15px", padding: "15px 10px", textAlign: "center" }}>
                        <CheckOutlinedIcon
                          style={{
                            fontSize: "2rem",
                            color: Colors.success,
                            background: Colors.textfield,
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                        />
                        <Typography variant="body1" mb={1} fontWeight={"bold"} fontSize={"0.9rem"}>
                          Request sent
                        </Typography>
                        <Typography variant="body2" mb={1.5} fontSize={"0.75rem"}>
                          An API key should appear on your Healthie dashboard within 24-48 hours. Please check the email attached to your Healthie
                          account. Need further assistance?{" "}
                          <a href="https://calendly.com/penciled/shawn" style={{ color: Colors.primary }}>
                            contact us
                          </a>
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ marginTop: "20px", background: Colors.bg3, borderRadius: "15px", padding: "12px" }}>
                        <span style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "7px" }}>
                          <MailOutlineOutlinedIcon
                            style={{ fontSize: "2rem", color: Colors.primary, background: Colors.textfield, borderRadius: "50%", padding: "5px" }}
                          />
                          <Typography variant="body1" fontWeight={"bold"} fontSize={"0.9rem"}>
                            Request API key
                          </Typography>
                        </span>

                        <Typography variant="body2" mb={1.5} fontSize={"0.9rem"}>
                          We’ll send Healthie’s team a personalised email requesting for an API key on your behalf.
                        </Typography>
                        <PrimaryButton sx={{ fontSize: "0.9rem", whiteSpace: "nowrap", padding: "5px 15px" }} onClick={handleApiKeyResponse}>
                          {sendRequestLoading ? <CircularProgress size={20} color="inherit" /> : "Send Request"}
                        </PrimaryButton>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            )}
          </>
        )}

        {currentStep === AgentCreationStep.SelectAgent ? (
          <>
            {/* <PrimaryButton 
              onClick={handleContinue}
              sx={{ 
                padding: '8px 20px', 
                fontSize: '0.95rem', 
                marginLeft: 'auto',
                '&:disabled': {
                  backgroundColor: '#31364B',
                  color: Colors.info,
                },
              }}
            >
              Continue
            </PrimaryButton>  */}
          </>
        ) : (
          <>
            {showRequestApiKey ? (
              <FooterBox onClick={handleSaveProgress} disabled={!requestSent} buttonText="Save and Continue Later" loading={saveProgressLoading} />
            ) : (
              <FooterBox onClick={handleClick} disabled={!isAgentCreationReady} buttonText="Customize Agent" loading={agentCreateLoading} />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default AgentCardSelections;
