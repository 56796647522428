import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { CircularProgress, Typography } from "@mui/material";
import { Colors } from "../Colors";
import { StyledPasswordTextField, TextFieldWithBottomPadding } from "../styles";
import { EHR, FrontendUser, Organization } from "../types";
import { EHRLogos, convertSnakeCaseToOfficial } from "../utils/utils";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { PrimaryButton } from "./CustomButton";
import { handleTokenUpload, handleDeleteIntegration } from "../slices/HealthieIntegrationSlice";
import { getSession } from "../slices/AuthSlice";

interface IntegrationCardWithTokenProps {
  ehr: EHR;
}

const IntegrationCardWithToken: React.FC<IntegrationCardWithTokenProps> = ({ ehr }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const loadingUploadingToken = useSelector((state: RootState) => state.healthieIntegration.loadingUploadingToken);
  const loadingDeletingIntegration = useSelector((state: RootState) => state.healthieIntegration.loadingDeletingIntegration);

  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);

  const [token, setToken] = useState<string>("");
  const [showAestheticField, setShowAestheticField] = useState<boolean>(true);
  const loadingProviders = useSelector((state: RootState) => state.healthieIntegration.loadingProviders);
  const loadingAppointments = useSelector((state: RootState) => state.healthieIntegration.loadingAppointments);

  const dispatch = useAppDispatch();

  const handleBlur = () => {
    setShowAestheticField(true);
  };

  useEffect(() => {
    if (token === "") {
      setShowAestheticField(true);
    }
  }, [token]);

  const handleTokenUploadLocal = async (ehrToken: string) => {
    if (!user?.token) { return; }
    await dispatch(handleTokenUpload({ token: user.token, ehr, ehrToken }));
  };

  const handleDeleteIntegrationLocal = async () => {
    if (!user?.token) {
      return;
    }
    await dispatch(handleDeleteIntegration({ token: user.token, ehr }));
    await dispatch(getSession());
  };

  const handleFocus = () => {
    setShowAestheticField(false);
  };

  const handleTokenChange = (value: string) => {
    setToken(value);
    if (value === "") {
      setShowAestheticField(true);
    }
  };

  return (
    <Box
      sx={{
        padding: "25px",
        backgroundColor: Colors.bg2,
        height: "280px",
        width: { xs: "100%", md: "45%", lg: "32%" },
        borderRadius: "20px",
        marginRight: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
        color: "white",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", gap: "15px", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <Typography variant="h6">{convertSnakeCaseToOfficial(ehr)}</Typography>
          {
            loadingProviders && !loadingDeletingIntegration && !loadingUploadingToken && (
              <CircularProgress size={24} color="inherit" style={{ marginLeft: "10px" }} />
            )
            // <LoadingWithMessage message="Loading providers..." size={30} />
          }
          {
            loadingAppointments && !loadingDeletingIntegration && !loadingUploadingToken && (
              <CircularProgress size={24} color="inherit" style={{ marginLeft: "10px" }} />
            )
            // <LoadingWithMessage message="Loading appointment types..." size={30} />
          }
        </div>

        <div
          style={{
            backgroundColor: organization?.integrations?.includes(EHR.healthie) ? Colors.success : Colors.error,
            color: "white",
            fontSize: "0.65rem",
            padding: "3px 10px",
            borderRadius: "25px",
          }}
        >
          {organization?.integrations?.includes(EHR.healthie) ? "Connected" : "Not connected"}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={EHRLogos[EHR.healthie]}
          alt={`${convertSnakeCaseToOfficial(ehr)} logo`}
          style={{ marginTop: "20px", width: "250px", height: "auto" }}
        />
      </div>
      <br />

      {!loadingProviders && !loadingAppointments && !organization?.integrations?.includes(EHR.healthie) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderColor: "#FFFFFF",
            "& .MuiInputLabel-root": {
              color: "#FFFFFF",
            },
          }}
        >
          <TextFieldWithBottomPadding
            label={`${convertSnakeCaseToOfficial(ehr)}` + " API token"}
            variant="outlined"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            fullWidth
          />
          <PrimaryButton onClick={() => handleTokenUploadLocal(token)} style={{ marginTop: "10px" }}>
            {loadingUploadingToken ? <CircularProgress size={24} color="inherit" /> : "Submit Token"}
          </PrimaryButton>
        </Box>
      )}
      {!loadingProviders && !loadingAppointments && organization?.integrations?.includes(EHR.healthie) && (
        <>
          {showAestheticField ? (
            <Box>
              <StyledPasswordTextField
                variant="outlined"
                type="text"
                placeholder="●●●●●●●●"
                onFocus={handleFocus}
                sx={{ width: "100%", marginBottom: "10px" }}
              />
              <PrimaryButton
                onClick={handleDeleteIntegrationLocal}
                sx={{
                  backgroundColor: Colors.error,
                  padding: "10px 20px",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: Colors.errorDark,
                  },
                }}
              >
                {loadingDeletingIntegration ? <CircularProgress size={24} color="inherit" /> : "Disconnect"}
              </PrimaryButton>
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center" }}>
              <TextFieldWithBottomPadding
                label={`${convertSnakeCaseToOfficial(ehr)}` + " API token"}
                variant="outlined"
                value={token}
                onChange={(e) => handleTokenChange(e.target.value)}
                fullWidth
                onFocus={handleFocus}
                onBlur={handleBlur}
                sx={{ marginBottom: "10px" }}
              />
              <PrimaryButton
                onClick={() => {
                  handleTokenUploadLocal(token);
                }}
              >
                {loadingUploadingToken ? <CircularProgress size={20} color="inherit" /> : "Update token"}
              </PrimaryButton>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default IntegrationCardWithToken;
