import * as React from "react";
import Box from "@mui/material/Box";
import SignoutHeader from "../subcomponents/SignoutHeader";
import Typography from "@mui/material/Typography";
import { ReactHTMLElement, useEffect, useRef } from "react";
import { PrimaryButton } from "../subcomponents/CustomButton";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { FrontendUser, HealthieWaitlistPatient, PatientGroup } from "../types";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputBase,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { dropdownStyles, menuPropsStyles, Sidebar } from "../styles/GeneralStyles";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import UpdatePatientModal from "../forms/UpdatePatientModal";
import PatientPriority, { NumberTextField } from "../subcomponents/PatientPriority";
import AddPatientModal from "../forms/AddPatientModal";
import { RootState, useAppDispatch } from "../store";
import { useSelector } from "react-redux";
import { Colors } from "../Colors";
import { api, formatIsoToCustomDateStringWithEEEE } from "../utils/utils";
import { deletePatient, getWaitlistPatients, updatePriorities } from "../slices/PatientSlice";
import { FooterBox, HeaderBox } from "../subcomponents/SidebarComponents";
import { group } from "console";
import { getSession, setOrganizationAttribute, updateOrganizationAttribute } from "../slices/AuthSlice";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const PatientCard: React.FC<{ patient: HealthieWaitlistPatient; updatePatient: (id: string) => void; updateOpen: boolean }> = ({
  patient,
  updatePatient,
  updateOpen,
}) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization = useSelector((state: RootState) => state.auth.organization);
  const loadingDeletePatient: boolean = useSelector((state: RootState) => state.patients.loadingDeletePatient);
  const loadingUpdatePatient: boolean = useSelector((state: RootState) => state.patients.loadingUpdatePatient);
  const dispatch = useAppDispatch();
  const groups = organization?.patientGroups || [];
  const groupName = groups.find((group) => group.patientGroupId === patient.patientGroupId)?.name;

  return (
    <Box
      sx={{
        backgroundColor: "rgba(256, 256, 256, 0.04)",
        borderRadius: "15px",
        padding: "12px",
        margin: "20px 0",
        position: "relative",
      }}
    >
      <Typography variant="h6" fontWeight="bold" fontSize="1rem">
        {patient.firstName} {patient.lastName}
      </Typography>
      <Box color={Colors.info} display="flex" alignItems="center" gap="10px">
        <Typography variant="body2">{patient.phoneNumber}</Typography>
        <CircleIcon sx={{ fontSize: "5px" }} />
        <Typography variant="body2">{`ID: ${patient.healthiePatientId}`}</Typography>
        {groupName && (
          <>
            <CircleIcon sx={{ fontSize: "5px" }} />
            <Typography variant="body2">{`Group: ${groupName}`}</Typography>
          </>
        )}
      </Box>
      <Typography variant="body2" fontWeight="bold" mt={2}>
        Availability:
      </Typography>
      <Typography variant="body2" color={Colors.info}>
        {patient.availableDates.map((date) => formatIsoToCustomDateStringWithEEEE(date)).join(", ")}
      </Typography>
      {patient.availableDates.length === 0 && (
        <Typography variant="body2" color={Colors.info}>
          No available dates
        </Typography>
      )}
      <Typography
        onClick={() => {
          updatePatient(patient.patientId);
        }}
        color="primary"
        fontWeight="bold"
        sx={{ fontSize: "0.8rem", marginTop: "10px", cursor: "pointer" }}
      >
        Update Patient
      </Typography>
      {!updateOpen && (
        <Tooltip title="Archive Patient" arrow>
          <IconButton
            className="close-icon"
            disabled={loadingDeletePatient || loadingUpdatePatient}
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
              color: "#FFF",
              padding: "5px",
              visibility: loadingDeletePatient || loadingUpdatePatient ? "hidden" : "visible",
              opacity: 0.8,
              transition: "opacity 0.3s",
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={async () => {
              if (!user?.token) {
                return;
              }
              if (!loadingDeletePatient && !loadingUpdatePatient) {
                await dispatch(deletePatient({ token: user.token, patientId: patient.patientId }));
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
interface AddGroupModalProps {
  patients: HealthieWaitlistPatient[];
  onClick: () => void;
  onClose: () => void;
}

const AddGroupModal: React.FC<AddGroupModalProps> = ({ patients, onClick, onClose }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [selectedPatients, setSelectedPatients] = React.useState<string[]>([]);
  const [groupName, setGroupName] = React.useState<string>("");
  const patientOptions = patients.map((patient) => ({ value: patient.patientId, label: `${patient.firstName} ${patient.lastName}` }));

  const createGroup = async () => {
    if (!user?.token) return;
    await api.post("/patients/groups", user.token, { name: groupName, patientIds: selectedPatients });
    onClose();
  };

  const handleSelectAllPatients = () => {
    setSelectedPatients(patientOptions.map((option) => option.value));
  };

  const handleDeselectAllPatients = () => {
    setSelectedPatients([]);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Prevent Backspace from deselecting the selected options
    if (event.key === "Backspace") {
      event.stopPropagation();
    }
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "100%",
        paddingBottom: "60px",
        paddingRight: "20px",
        "& .MuiOutlinedInput-root": {
          color: "#FFFFFF",
        },
        "& .MuiInputLabel-root": {
          color: Colors.info,
        },
        "& .MuiInputBase-input": {
          color: "#FFFFFF",
        },
        "& .MuiSvgIcon-root": {
          color: "#FFF",
        },
        "& .MuiAutocomplete-paper": {
          backgroundColor: Colors.bg3,
          margin: 0,
        },
        "& .MuiAutocomplete-listbox": {
          backgroundColor: Colors.bg3,
        },
      }}
    >
      <HeaderBox title="Add Group" onClose={onClose} />

      <Box mt={"60px"}>
        <Typography variant="body1">Group Name</Typography>
        <FormControl fullWidth>
          <TextField fullWidth label="Group Name" variant="outlined" value={groupName} onChange={(e) => setGroupName(e.target.value)} />
        </FormControl>
      </Box>

      <FormControl fullWidth>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" mt={2}>
          <Typography variant="body1">Patients</Typography>
          {selectedPatients.length === patientOptions.length ? (
            <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllPatients}>
              Deselect All
            </Button>
          ) : (
            <Button sx={{ textTransform: "none" }} onClick={handleSelectAllPatients}>
              Select All
            </Button>
          )}
        </Box>

        <Autocomplete
          multiple
          options={patientOptions}
          getOptionLabel={(option) => option.label}
          value={selectedPatients.map((value) => patientOptions.find((option) => option.value === value)).filter((value) => value !== undefined)}
          onChange={(event, newValue) => setSelectedPatients(newValue.map((option) => option.value))}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Select patients" onKeyDown={handleKeyDown} />}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox sx={{ mr: 1, color: "white" }} checked={selected} />
              <ListItemText primary={option.label} />
            </li>
          )}
          renderTags={() => null}
          disableCloseOnSelect
          isOptionEqualToValue={(option, value) => option.value === value.value}
          PaperComponent={(props) => (
            <Paper
              {...props}
              sx={{
                backgroundColor: Colors.bg3,
                color: "#FFF",
                margin: 0,
                borderRadius: "15px",
              }}
            />
          )}
          ListboxComponent={(props) => (
            <ul
              {...props}
              style={{
                padding: 0,
                margin: 0,
                background: Colors.bg3,
              }}
            />
          )}
          sx={{
            borderRadius: "10px",
            background: Colors.textfield,
            marginTop: "5px",
          }}
        />
      </FormControl>

      {selectedPatients.length > 0 && (
        <Box>
          <Typography variant="body1" mt={2}>
            Selected Patients:
          </Typography>
          <Box
            sx={{
              maxHeight: "400px", // Set your desired max height
              overflowY: "auto", // Enable vertical scrolling
              border: "1px solid #ddd", // Optional: add border for visual distinction
              padding: "8px", // Optional: add padding
            }}
          >
            {selectedPatients.map((patientId) => {
              const patient = patients.find((patient) => patient.patientId === patientId);
              return (
                patient && (
                  <Box key={patientId}>
                    {patient.firstName} {patient.lastName}
                  </Box>
                )
              );
            })}
          </Box>
        </Box>
      )}

      {<FooterBox onClick={createGroup} disabled={!groupName} buttonText="Add Group" loading={false} />}
    </Box>
  );
};

interface UpdateGroupModalProps {
  patients: HealthieWaitlistPatient[];
  groupPatientIds: string[];
  patientGroup: PatientGroup;
  onClick: () => void;
  onClose: () => void;
}

const UpdateGroupModal: React.FC<UpdateGroupModalProps> = ({ patients, groupPatientIds, patientGroup, onClick, onClose }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [selectedPatients, setSelectedPatients] = React.useState<string[]>(groupPatientIds);
  const [groupName, setGroupName] = React.useState<string>(patientGroup.name);
  const patientOptions = patients.map((patient) => ({ value: patient.patientId, label: `${patient.firstName} ${patient.lastName}` }));

  const updateGroup = async () => {
    if (!user?.token) return;
    await api.patch(`/patients/groups/${patientGroup.patientGroupId}`, user.token, { name: groupName, patientIds: selectedPatients });
    onClose();
  };

  const handleSelectAllPatients = () => {
    setSelectedPatients(patientOptions.map((option) => option.value));
  };

  const handleDeselectAllPatients = () => {
    setSelectedPatients([]);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Prevent Backspace from deselecting the selected options
    if (event.key === "Backspace") {
      event.stopPropagation();
    }
  };

  const selectedOptions = selectedPatients
    .map((value) => patientOptions.find((option) => option.value === value))
    .filter((value) => value !== undefined);

  interface HTMLUListElementWithScroll extends HTMLUListElement {
    savedScrollTop?: number;
  }

  const listboxRef = useRef<HTMLUListElementWithScroll | null>(null);

  const handleScroll = () => {
    if (listboxRef.current) {
      listboxRef.current.scrollTop = listboxRef.current.savedScrollTop || 0;
    }
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "100%",
        paddingBottom: "60px",
        paddingRight: "20px",
        "& .MuiOutlinedInput-root": {
          color: "#FFFFFF",
        },
        "& .MuiInputLabel-root": {
          color: Colors.info,
        },
        "& .MuiInputBase-input": {
          color: "#FFFFFF",
        },
        "& .MuiSvgIcon-root": {
          color: "#FFF",
        },
        "& .MuiAutocomplete-paper": {
          backgroundColor: Colors.bg3,
          margin: 0,
        },
        "& .MuiAutocomplete-listbox": {
          backgroundColor: Colors.bg3,
        },
      }}
    >
      <HeaderBox title="Add Group" onClose={onClose} />

      <Box mt={"60px"}>
        <Typography variant="body1">Group Name</Typography>
        <FormControl fullWidth>
          <TextField fullWidth label="Group Name" variant="outlined" value={groupName} onChange={(e) => setGroupName(e.target.value)} />
        </FormControl>
      </Box>

      <FormControl fullWidth>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" mt={2}>
          <Typography variant="body1">Patients</Typography>
          {selectedPatients.length === patientOptions.length ? (
            <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllPatients}>
              Deselect All
            </Button>
          ) : (
            <Button sx={{ textTransform: "none" }} onClick={handleSelectAllPatients}>
              Select All
            </Button>
          )}
        </Box>

        <Autocomplete
          multiple
          options={patientOptions}
          getOptionLabel={(option) => option.label}
          value={selectedOptions}
          onChange={(event, newValue) => {
            if (listboxRef.current) {
              listboxRef.current.savedScrollTop = listboxRef.current.scrollTop;
            }
            setSelectedPatients(newValue.map((option) => option.value));
          }}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Select patients" onKeyDown={handleKeyDown} />}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox sx={{ mr: 1, color: "white" }} checked={selected} />
              <ListItemText primary={option.label} />
            </li>
          )}
          renderTags={() => null}
          disableCloseOnSelect
          isOptionEqualToValue={(option, value) => option.value === value.value}
          PaperComponent={(props) => (
            <Paper
              {...props}
              sx={{
                backgroundColor: Colors.bg3,
                color: "#FFF",
                margin: 0,
                borderRadius: "15px",
              }}
            />
          )}
          ListboxComponent={(props) => (
            <ul
              {...props}
              ref={listboxRef}
              onScroll={handleScroll}
              style={{
                padding: 0,
                margin: 0,
                background: Colors.bg3,
              }}
            />
          )}
          sx={{
            borderRadius: "10px",
            background: Colors.textfield,
            marginTop: "5px",
          }}
        />
      </FormControl>

      {selectedPatients.length > 0 && (
        <Box>
          <Typography variant="body1" mt={2}>
            Selected Patients:
          </Typography>
          <Box
            sx={{
              maxHeight: "400px", // Set your desired max height
              overflowY: "auto", // Enable vertical scrolling
              border: "1px solid #ddd", // Optional: add border for visual distinction
              padding: "8px", // Optional: add padding
            }}
          >
            {selectedPatients.map((patientId) => {
              const patient = patients.find((patient) => patient.patientId === patientId);
              return (
                patient && (
                  <Box key={patientId}>
                    {patient.firstName} {patient.lastName}
                  </Box>
                )
              );
            })}
          </Box>
        </Box>
      )}

      {<FooterBox onClick={updateGroup} disabled={!groupName} buttonText="Update Group" loading={false} />}
    </Box>
  );
};

type GroupPriorityProps = {
  sortedGroups: PatientGroup[];
  open: boolean;
  onClose: (newGroups: PatientGroup[]) => void;
};

const GroupPriority: React.FC<GroupPriorityProps> = ({ sortedGroups, open, onClose }) => {
  const [groups, setGroups] = React.useState(sortedGroups);

  function moveElement<T>(array: T[], fromIndex: number, toIndex: number): T[] {
    const newArray = [...array]; // Create a copy of the array
    const [element] = newArray.splice(fromIndex, 1); // Remove the element from the fromIndex
    newArray.splice(toIndex, 0, element); // Insert the element at the toIndex
    return newArray;
  }

  const moveRowUp = (index: number) => {
    if (index === 0) return;
    setGroups(moveElement(groups, index, index - 1));
  };

  const moveRowDown = (index: number) => {
    if (index === groups.length - 1) return;
    setGroups(moveElement(groups, index, index + 1));
  };

  const handlePriorityChange = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= groups.length) return;
    setGroups(moveElement(groups, fromIndex, toIndex));
  };

  const columnTypes = ["Priority", "Name"];

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "92%",
        paddingBottom: "40px",
        paddingRight: "20px",
        background: "transparent",
        borderRadius: "10px",
      }}
    >
      <HeaderBox title="Group Priority" onClose={() => onClose(groups)} />

      <Table
        sx={{
          width: "100%",
          marginTop: "30px",
          background: Colors.bg2,
          borderRadius: "15px",
          "& .MuiTableCell-root": {
            color: "#FFF",
            borderColor: "rgba(255, 255, 255, 0.1)",
          },
        }}
      >
        <TableHead>
          <TableRow>
            {columnTypes.map((type, index) => (
              <TableCell key={index} sx={{ color: "#FFF", fontWeight: "bold", borderRadius: "8px 8px 0 0" }}>
                {type}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.map((group, index) => (
            <TableRow key={group.patientGroupId}>
              <TableCell>
                <NumberTextField
                  key={index}
                  minValue={1}
                  maxValue={groups.length}
                  defaultValue={index + 1}
                  onChange={(value) => handlePriorityChange(index, value - 1)}
                />
                <IconButton
                  onClick={() => moveRowUp(index)}
                  disabled={index === 0}
                  sx={{
                    color: index === 0 ? "#555" : "#FFF",
                  }}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton
                  onClick={() => moveRowDown(index)}
                  disabled={index === groups.length - 1}
                  sx={{
                    color: index === groups.length - 1 ? "#555" : "#FFF",
                  }}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </TableCell>
              <TableCell>{group.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const Patients: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const loadingPatients = useSelector((state: RootState) => state.patients.loadingFetchPatients);
  const loadingDeletePatient: boolean = useSelector((state: RootState) => state.patients.loadingDeletePatient);
  const loadingUpdatePatient: boolean = useSelector((state: RootState) => state.patients.loadingUpdatePatient);

  const organization = useSelector((state: RootState) => state.auth.organization);
  const groups = organization?.patientGroups || [];

  const [addPatientModalOpen, setAddPatientModalOpen] = React.useState<boolean>(false);

  const [patientPriorityModalOpen, setPatientPriorityModalOpen] = React.useState(false);
  const [groupPriorityModalOpen, setGroupPriorityModalOpen] = React.useState(false);
  const patients = useSelector((state: RootState) => state.patients.patients);
  const [updateOpen, setUpdateOpen] = React.useState(false);
  const [updateGroupOpen, setUpdateGroupOpen] = React.useState(false);
  const [selectedPatient, setSelectedPatient] = React.useState<any | undefined>(undefined);
  const [addGroupModalOpen, setAddGroupModalOpen] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState<PatientGroup | undefined>(undefined);
  const [selectedGroupPatientIds, setSelectedGroupPatientIds] = React.useState<string[] | undefined>(undefined);

  const [query, setQuery] = React.useState<string>("");

  const dispatch = useAppDispatch();

  // remove all special characters and spaces
  function simplifyString(str: string) {
    return str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  }

  function filterPatients(patients: HealthieWaitlistPatient[], query: string) {
    return patients.filter((patient) => {
      const patientString = simplifyString(`${patient.firstName} ${patient.lastName} ${patient.phoneNumber}`);
      const simpleQuery = simplifyString(query);
      return patientString.includes(simpleQuery);
    });
  }

  const filteredPatients = filterPatients(patients, query);

  useEffect(() => {
    if (user?.token) {
      dispatch(getWaitlistPatients({ token: user.token, quietly: true }));
    }
  }, [user?.token]);

  const onPatientPriorityClose = async (patients: any) => {
    if (!user?.token) {
      return;
    }
    setPatientPriorityModalOpen(false);
    await updatePatientPriorities(patients);
    await dispatch(getWaitlistPatients({ token: user.token, quietly: false }));
  };

  const onGroupPriorityClose = async (groups: PatientGroup[]) => {
    if (!user?.token) {
      return;
    }
    setGroupPriorityModalOpen(false);
    await dispatch(
      updateOrganizationAttribute({ token: user.token, attribute: "patientGroupIds", value: groups.map((group) => group.patientGroupId) })
    );
    await dispatch(setOrganizationAttribute({ attribute: "patientGroups", value: groups }));
  };

  const updatePatientPriorities = async (patients: any) => {
    if (!user?.token) {
      return;
    }
    const priorities = patients.map((patient: any, index: number) => {
      return { patientId: patient.patientId, priority: index + 1 };
    });
    await dispatch(updatePriorities({ token: user?.token!, patients: priorities }));
  };

  const updatePatient = async (patientId: string) => {
    setSelectedPatient(patients.find((patient: HealthieWaitlistPatient) => patient.patientId === patientId));
    setUpdateOpen(true);
  };

  const onAddGroupModalClose = async () => {
    if (user?.token) {
      await dispatch(getWaitlistPatients({ token: user.token, quietly: true }));
      await dispatch(getSession());
    }
    setAddGroupModalOpen(false);
  };

  const onUpdateGroupModalClose = async () => {
    if (user?.token) {
      await dispatch(getWaitlistPatients({ token: user.token, quietly: true }));
      await dispatch(getSession());
    }
    setUpdateGroupOpen(false);
  };

  const onSelectGroup = (group: PatientGroup) => {
    setSelectedGroup(group);
    setSelectedGroupPatientIds(patients.filter((patient) => patient.patientGroupId === group.patientGroupId).map((patient) => patient.patientId));
    setUpdateGroupOpen(true);
  };

  const deleteGroup = async (group: PatientGroup) => {
    if (!user?.token) {
      return;
    }
    await api.delete(`/patients/groups/${group.patientGroupId}`, user.token);
    await dispatch(getWaitlistPatients({ token: user.token, quietly: true }));
    await dispatch(getSession());
  };

  return (
    <Box
      sx={{
        marginTop: { xs: "75px", sm: 0, color: "#FFF" },
        "& .MuiOutlinedInput-root": {
          color: "#FFFFFF",
        },
        "& .MuiInputLabel-root": {
          color: Colors.info,
        },
        "& .MuiInputBase-input": {
          color: "#FFFFFF",
        },
        "& .MuiOutlinedInput-input": {
          color: "#FFFFFF",
        },
        "& .MuiSvgIcon-root": {
          color: "#FFF",
        },
        "& .MuiAutocomplete-paper": {
          backgroundColor: Colors.bg3,
          margin: 0,
        },
        "& .MuiAutocomplete-listbox": {
          backgroundColor: Colors.bg3,
        },
      }}
    >
      {loadingPatients && <LoadingWithMessage message="Loading patients..." size={20} customStyles={{ marginTop: "20px" }} />}
      {loadingDeletePatient && <LoadingWithMessage message="Deleting patient..." size={20} customStyles={{ marginTop: "20px" }} />}
      {loadingUpdatePatient && <LoadingWithMessage message="Updating patient..." size={20} customStyles={{ marginTop: "20px" }} />}
      {!loadingPatients && (
        <Box display="flex" flexDirection="row" gap={10}>
          <Box flex={1}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6">Patients</Typography>
                <AddIcon onClick={() => setAddPatientModalOpen(true)} style={{ color: Colors.primary, cursor: "pointer", marginLeft: "5px" }} />
              </Box>
              <PrimaryButton onClick={() => setPatientPriorityModalOpen(true)} style={{ padding: "2px 10px", fontSize: "0.9rem" }}>
                Set Priority
              </PrimaryButton>
            </Box>
            <Paper
              component="form"
              sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400, backgroundColor: Colors.bg3, color: "white" }}
            >
              <TextField
                sx={{ flex: 1, color: "white" }}
                placeholder="Search"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
              <IconButton sx={{ p: "10px" }}>
                <SearchIcon />
              </IconButton>
            </Paper>
            {filteredPatients.map((patient: HealthieWaitlistPatient) => (
              <PatientCard key={patient.patientId} patient={patient} updatePatient={updatePatient} updateOpen={updateOpen} />
            ))}
          </Box>
          <Box flex={1}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6">Groups</Typography>
                <AddIcon onClick={() => setAddGroupModalOpen(true)} style={{ color: Colors.primary, cursor: "pointer", marginLeft: "5px" }} />
              </Box>
              <PrimaryButton onClick={() => setGroupPriorityModalOpen(true)} style={{ padding: "2px 10px", fontSize: "0.9rem" }}>
                Set Priority
              </PrimaryButton>
            </Box>
            {groups.map((group) => (
              <Box key={group.patientGroupId}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(256, 256, 256, 0.12)",
                    borderRadius: "15px",
                    padding: "12px",
                    margin: "20px 0",
                    position: "relative",
                  }}
                >
                  {group.name}{" "}
                  <IconButton onClick={() => onSelectGroup(group)} style={{ color: Colors.primary, position: "absolute", right: "10px" }}>
                    <EditIcon />
                  </IconButton>
                  {patients.filter((patient) => patient.patientGroupId === group.patientGroupId).length === 0 && (
                    <IconButton onClick={() => deleteGroup(group)} style={{ color: Colors.primary, position: "absolute", right: "40px" }}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
                {patients
                  .filter((patient) => patient.patientGroupId === group.patientGroupId)
                  .map((patient) => (
                    <PatientCard key={patient.patientId} patient={patient} updatePatient={updatePatient} updateOpen={updateOpen} />
                  ))}
              </Box>
            ))}
          </Box>
        </Box>
      )}
      <Modal open={addPatientModalOpen} onClose={() => setAddPatientModalOpen(false)}>
        <Sidebar className={addPatientModalOpen ? "open" : ""}>
          <AddPatientModal
            onClick={() => {}}
            onClose={() => {
              setAddPatientModalOpen(false);
            }}
          />
        </Sidebar>
      </Modal>
      <Modal open={addGroupModalOpen} onClose={onAddGroupModalClose}>
        <Sidebar className={addGroupModalOpen ? "open" : ""}>
          <AddGroupModal patients={patients} onClick={() => {}} onClose={onAddGroupModalClose} />
        </Sidebar>
      </Modal>
      {selectedPatient && (
        <Sidebar className={updateOpen ? "open" : ""}>
          <UpdatePatientModal key={selectedPatient.patientId} open={updateOpen} onClose={() => setUpdateOpen(false)} patient={selectedPatient} />
        </Sidebar>
      )}
      {selectedGroup && selectedGroupPatientIds && (
        <Sidebar className={updateGroupOpen ? "open" : ""}>
          <UpdateGroupModal
            key={selectedGroup.patientGroupId}
            onClose={onUpdateGroupModalClose}
            onClick={() => {}}
            patients={patients}
            patientGroup={selectedGroup}
            groupPatientIds={selectedGroupPatientIds}
          />
        </Sidebar>
      )}

      <Sidebar className={patientPriorityModalOpen ? "open" : ""}>
        <PatientPriority key={patients.length} open={patientPriorityModalOpen} onClose={onPatientPriorityClose} sortedPatients={patients} />
      </Sidebar>
      <Sidebar className={groupPriorityModalOpen ? "open" : ""}>
        <GroupPriority key={groups.length} open={groupPriorityModalOpen} onClose={onGroupPriorityClose} sortedGroups={groups} />
      </Sidebar>
    </Box>
  );
};

export default Patients;
