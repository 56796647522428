import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Agent, AgentType, Call, CallStatuses, FrontendUser, OutcomeSentiment } from "../types";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { useNavigate, useParams } from "react-router-dom";
import SignoutHeader from "../subcomponents/SignoutHeader";
import { DateTime } from "luxon";
import { Modal, Typography } from "@mui/material";
import { Colors } from "../Colors";
import { clearCalls, nextAgentCallsPaginated, refreshAgentCallsPaginated } from "../slices/CallSlice";
import { useEffect } from "react";
import FilterForm from "../forms/FilterForm";
import { SecondaryButton } from "../subcomponents/CustomButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Loader from "../subcomponents/Loader";
import { usePostHog } from "posthog-js/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { callStatusColor, callStatusDisplay, convertSnakeCaseToOfficial, getOutcomeColor } from "../utils/utils";
import { Sidebar } from "../styles/GeneralStyles";
import ExportModal from "../forms/ExportModal";
import ConfigureForm from "../forms/ConfigureForm";
import SettingsIcon from "@mui/icons-material/Settings";

type OnRowClick = (call: Call) => void;

const ConversationHistoryRow: React.FC<{ onRowClick: OnRowClick; call: Call }> = ({ onRowClick, call }) => {
  const callDate = DateTime.fromISO(call.startedAt);
  const date = callDate.toFormat("M/d");
  const time = callDate.toFormat("h:mm a");
  const patientName = `${call.patientFirstName} ${call.patientLastName}`;
  const type = "Phone call"; // TODO (Hizami): Get type from the backend
  const outcome = call.outcome as string;
  const outcomeSentiment = call.outcomeSentiment as OutcomeSentiment;
  const status = call.status as keyof typeof CallStatuses;

  return (
    <Box
      onClick={() => onRowClick(call)}
      sx={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "20px",
        backgroundColor: Colors.bg2,
        padding: { xs: "10px 15px", sm: "20px 45px" },
        borderRadius: "25px",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: Colors.secondaryDark,
        },
      }}
    >
      <Typography
        variant="body2"
        sx={{
          marginRight: { xs: "20px", sm: "50px" },
          color: Colors.grey3,
          width: { xs: "25%", sm: "15%" },
          fontSize: { xs: "0.9rem", sm: "0.9rem" },
        }}
      >
        <strong>{date}</strong>
        <br /> {time}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: { xs: "80%", sm: "85%" },
          alignItems: { xs: "normal", sm: "center" },
          justifyContent: { xs: "normal", sm: "space-between" },
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" sx={{ fontSize: { xs: "1.2rem", sm: "1.3rem" }, color: '#FFF' }}>
            {patientName}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: { xs: "0.9rem", sm: "1rem" }, color: Colors.grey3 }}>
            {" "}
            {type}{" "}
          </Typography>
        </div>

        <Box sx={{display: "flex", flexDirection:{xs: "column", sm: "row"}, justifyContent: {xs: "normal", sm: "space-between"}, gap: {xs: 0, sm: 8} }}>
          {status && (
            <Box sx={{display: 'flex', flexDirection: {xs: 'row', sm: 'column'}, alignItems: 'center', gap: {xs: 1, sm: 0} }}>
              <Typography variant='subtitle2' style={{color: Colors.grey3, fontSize: "0.9rem"}}>
                Call status:
              </Typography> 

              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "1rem", sm: "1.1rem" }, marginLeft: { xs: "normal", sm: "auto" }, color: callStatusColor(status as CallStatuses)}}
              >
                {" "}
                {callStatusDisplay(status as CallStatuses)}
              </Typography>
            </Box>
          )}

          {outcome && (
            <Box sx={{display: 'flex', flexDirection: {xs: 'row', sm: 'column'}, alignItems: 'center', gap: {xs: 1, sm: 0} }}>
              <Typography variant='subtitle2' style={{color: Colors.grey3, fontSize: "0.9rem"}}>
                Call outcome:
              </Typography>

              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "1rem", sm: "1.1rem" }, marginLeft: { xs: "normal", sm: "auto" }, color: getOutcomeColor(outcomeSentiment)}}
              >
                {" "}
                {convertSnakeCaseToOfficial(outcome as string)}
              </Typography>
            </Box>

          )}
        </Box>
        
      </Box>
      <ChevronRightIcon onClick={() => onRowClick(call)} sx={{ display: { xs: "block", sm: "none" }, marginLeft: "auto" }} />
    </Box>
  );
};

const AgentConversationHistory: React.FC = () => {
  const { agentId } = useParams<{ agentId: string }>();

  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const agent: Agent | null = useSelector(
    (state: RootState) => state.agents.agents.find((agent) => agent.agentId === agentId) || null
  );
  const calls = useSelector((state: RootState) => state.calls.calls);
  const navigate = useNavigate();
  const finalPage = useSelector((state: RootState) => state.calls.finalPage);

  const [agentsFilter, setAgentsFilter] = React.useState<string[] | undefined>(undefined);
  const lastEvaluatedKey = useSelector((state: RootState) => state.calls.lastEvaluatedKey);
  const [datesFilter, setDatesFilter] = React.useState<[DateTime | null, DateTime | null] | undefined>(undefined);
  const [index, setIndex] = React.useState<number>(0);
  const loading = useSelector((state: RootState) => state.calls.loading);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  
  const posthog = usePostHog();

  const pageSize = 8;

  const dispatch = useAppDispatch();

  const token = user?.token || "";

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const setFilter = (agents: string[], dates: [DateTime | null, DateTime | null]) => {
    setAgentsFilter(agents);
    setDatesFilter(dates);
    getCallsPaginatedInitial(dates);
    setIsModalOpen(false);
  };

  const getCallsPaginatedInitial = async (dates?: [DateTime | null, DateTime | null]) => {
    if (!token) {
      clearCalls();
      setIndex(0);
    } else {
      dispatch(refreshAgentCallsPaginated({ token, agentId: agentId || "", dateRange: dates })); // TODO (Hizami): Figure out why I need to cast this
      setIndex(0);
    }
  };

  const refreshCalls = async () => {
    dispatch(refreshAgentCallsPaginated({ token, agentId: agentId || "", dateRange: datesFilter })); // TODO (Hizami): Figure out why I need to cast this
    setIndex(0);
  };

  const getCallsPaginated = async () => {
    if (!token) {
      clearCalls();
      setIndex(0);
    } else {
      dispatch(nextAgentCallsPaginated({ token, agentId: agentId || "", lastEvaluatedKey, dateRange: datesFilter })); // TODO (Hizami): Figure out why I need to cast this
      setIndex(index + 1);
    }
  };

  const prevPage = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const nextPage = () => {
    // check to see if we already have the calls loaded
    if (calls && calls.length > (index + 1) * pageSize) {
      setIndex(index + 1);
    } else if (!finalPage) {
      getCallsPaginated();
    }
  };

  useEffect(() => {
    getCallsPaginatedInitial();
  }, [user?.token]);

  const endOfList = calls ? calls.length <= (index + 1) * pageSize : false;
  const pageCalls = calls ? calls.slice(index * pageSize, (index + 1) * pageSize) : [];

  const handleBack = () => {
    posthog?.capture("Agent Info Page - Back");
    if (agent?.agentType !== AgentType.inbound) {
      navigate(`/dashboard/agents/${agentId}`);
    } else { // this is an inbound agent
      navigate("/dashboard/agents");
    }
  };

  return (
    <Box>
      <SignoutHeader />

      { agent && (
        <>
          <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <Sidebar className={isModalOpen ? "open" : ""}>
              <ConfigureForm agent={agent} isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            </Sidebar>
          </Modal>
          
          <SecondaryButton onClick={handleBack} sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center", margin: "20px 0" }}>
            <ArrowBackIcon sx={{ marginRight: "10px" }} /> Back
          </SecondaryButton>
        </>
        )}

      <Box sx={{ width: "95%", margin: { xs: "70px 0 20px 0", lg: "0" } }}>
        <Typography variant="h4" sx={{ fontSize: { xs: "1.6rem", sm: "2.1rem" }, color: '#FFF'}}>
          {agent && `${agent.name}`}
        </Typography>

        <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "1rem", sm: "1.4rem" },
              lineHeight: { xs: "1", sm: "1.5" },
            }}
          >
            <span style={{ color: "grey", fontSize: "1.3rem" }}>{agent?.description}</span>
          </Typography>
        </Box>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6" sx={{ color: Colors.primary, marginBottom: "20px", fontSize: { xs: "1.05rem", sm: "1.3rem" } }}>
            Conversation History
          </Typography>
          <SecondaryButton onClick={() => setIsModalOpen(true)} sx={{ padding: { xs: "0", sm: "10px 20px" } }}>
            <SettingsIcon sx={{ marginRight: { xs: "0", sm: "7px" } }} />
            <Typography variant="h6" sx={{ fontSize: { xs: "0.8rem", sm: "1rem" }, display: { xs: "none", sm: "block" } }}>
              {" "}
              Configure{" "}
            </Typography>
          </SecondaryButton>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: { xs: "75px 0 20px 0", lg: "20px 0" } }}>
            <Box sx={{ display: "flex", gap: "10px" }}>
            <SecondaryButton onClick={() => setFilterModalOpen(true)} sx={{ padding: { xs: "3px 15px", sm: "5px 20px" } }}>
              Filter
            </SecondaryButton>
            <SecondaryButton onClick={() => setExportModalOpen(true)} sx={{ padding: { xs: "3px 15px", sm: "5px 20px" } }}>
              Export
            </SecondaryButton>
          </Box>
        </Box>
        </div>
        
        {loading ? (
           <Loader size={50} message="" customStyles={{ height: "100%", marginTop: "20vh " }} />
        ) : calls && calls.length > 0 ? (
          <>
            {pageCalls.map((call: Call) => (
              <ConversationHistoryRow
                key={call.callId}
                call={call}
                onRowClick={() => {
                  posthog?.capture("[PENCILED] agent-conversation_history_row_clicked", { callId: call.callId });
                  navigate(`/dashboard/agents/${agentId}/agent-conversation-history/calls/${call?.callId}`);
                }}
              />
            ))}
            <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
              <SecondaryButton onClick={refreshCalls}>Refresh</SecondaryButton>
              <SecondaryButton onClick={prevPage} disabled={index === 0}>
                Prev
              </SecondaryButton>
              <SecondaryButton onClick={nextPage} disabled={endOfList && finalPage}>
                Next
              </SecondaryButton>
            </div>
          </>
        ) : (
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              margin: "auto",
              marginTop: "10%",
              color: Colors.coolWhite,
            }}
          >
            No calls found
          </Typography>
        )}
      </Box>

      <Sidebar className={filterModalOpen || exportModalOpen ? "open" : ""}>
        {filterModalOpen && (
          <FilterForm 
            setFilter={setFilter} 
            agentsFilter={agentsFilter} 
            datesFilter={datesFilter} 
            agentsSelector={true} 
            onClose={() => setFilterModalOpen(false)}
          />
        )}
        {exportModalOpen && (
          <ExportModal
            agentsSelector={false}
            mainAgentsFilter={agentsFilter}
            onClose={() => setExportModalOpen(false)}
          />
        )}
      </Sidebar>
    </Box>
  );
};

export default AgentConversationHistory;
