import React, { useRef, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useSelector } from "react-redux";
import { RootState } from "../store";
import SignoutHeader from '../subcomponents/SignoutHeader';
import DeleteAccountModal from '../confirmationModals/deleteAccountModal';
import { PrimaryButton } from '../subcomponents/CustomButton';
import { Colors } from '../Colors';
import { FrontendUser, Organization, Agent } from "../types";
import axios from 'axios';
import { useSnackbar } from '../providers/SnackbarProvider';
import UploadLogoModal from '../forms/UploadLogo';
import { QRCodeCanvas } from 'qrcode.react';

const Account: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { showMessage } = useSnackbar();

  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const agents: Agent[] = useSelector((state: RootState) => state.agents.agents);
  const [uploadLogoModalOpen, setUploadLogoModalOpen] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const handleDeleteClick = () => {
    setOpenModal(true);
  };

  const handleFileChange = (file: File) => {
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setSelectedFile(file);
    } else {
      showMessage("Please select a JPG or PNG file", "warning");
    }
  };  

  const handleUploadClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('logo', selectedFile);
  
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/organization/upload-logo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        showMessage("Logo uploaded successfully", "success");
      } catch (error) {
        console.error("Error uploading logo:", error);
        showMessage("Failed to upload logo", "error");
      }
    } else {
      showMessage("No file selected", "warning");
    }
  };

  const handleDownloadQRCode = () => {
    if (canvasRef.current) {
      const url = canvasRef.current.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = url;
      link.download = `QRCode-${organization?.organizationId}.png`;
      link.click();
    }
  };

  return (
    <Box sx={{ color: '#FFF' }}>
      <SignoutHeader />
      <Typography variant='h5' sx={{ fontSize: { xs: "1.3rem", sm: "1.8rem" }, margin: { xs: "80px 0 10px 0", lg: "20px 0" } }}>My Account Info</Typography>

      <UploadLogoModal
        open={uploadLogoModalOpen}
        onClose={() => setUploadLogoModalOpen(false)}
        onUpload={handleFileChange}
      />
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography variant='h6' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>Name: </Typography>
        <Typography variant='subtitle1' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>{user?.firstName} {user?.lastName}</Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography variant='h6' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>Email: </Typography>
        <Typography variant='subtitle1' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}> {user?.email}</Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography variant='h6' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>Organization: </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem' }, margin: { xs: '20px 0 10px 0', md: '20px 0' } }}>
          {organization?.name || "Organization Name Not Added"}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography variant='h6' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>Callback Number: </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem' }, margin: { xs: '20px 0 10px 0', md: '20px 0' } }}>
          {organization?.phoneNumber || "Callback Number Not Added"}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography variant='h6' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>Timezone: </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem' }, margin: { xs: '20px 0 10px 0', md: '20px 0' } }}>
          {organization?.timezone || "Timezone Not Found"}
        </Typography>
      </Box>

      {/* File Upload Section */}
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginTop: '20px', marginBottom: "30px" }}>
        {/* Button to trigger file input */}
        <Button onClick={() => setUploadLogoModalOpen(true)}>
          Upload Logo
        </Button>

        {selectedFile && <Typography variant='subtitle1'>{selectedFile.name}</Typography>}
        
        <Button
          variant="contained"
          onClick={handleUploadClick}
          disabled={!selectedFile}
          sx={{
            padding: '16px 32px',  // Adjust padding as needed
            marginBottom: '20px',
            backgroundColor: Colors.secondary,
            "&:hover": {
              backgroundColor: Colors.secondaryDark,
            }
          }}
        >
          Upload
        </Button>
      </Box>

      {/* QR Code Section */}
      <Box sx={{ marginTop: "20px" }}>
        <Typography variant='h6' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, marginBottom: "10px" }}>
          Download QR Code for Self-Add
        </Typography>
        {organization && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column", // Stack elements vertically
                alignItems: "left",    // Center-align the elements
                marginTop: "20px"
              }}
            >
              <QRCodeCanvas
                value={`https://app.penciled.com/patient/self-add/${organization?.organizationId}`}
                size={128} // Adjust the size as needed
                fgColor="#000000"
                bgColor="#ffffff"
                level="L"
                includeMargin={false}
                ref={canvasRef} // Reference to the canvas element
              />
              <Button 
                variant="contained"
                sx={{ 
                  marginTop: "10px", 
                  backgroundColor: Colors.primary, 
                  "&:hover": { backgroundColor: Colors.primaryDark },
                  maxWidth: "150px",
                  maxHeight: "50px"
                }}
                onClick={handleDownloadQRCode}
              >
                Download QR Code
              </Button>
            </Box>
          </>
        )}
      </Box>

      <PrimaryButton
        onClick={handleDeleteClick}
        sx={{
          padding: '16px 32px',  // Adjust padding as needed
          marginTop: '20px',
          backgroundColor: Colors.error,
          "&:hover": {
            backgroundColor: Colors.errorDark,
          }
        }}
      >
        Delete Account
      </PrimaryButton>

      <DeleteAccountModal open={openModal} onClose={() => setOpenModal(false)} />
    </Box>
  );
};

export default Account;
