import React, { useEffect } from "react";
import { Typography, Box, FormControl, OutlinedInput, CircularProgress, InputAdornment, IconButton } from "@mui/material";
import { WhitePenciledLogo, StyledPenciledTextLogo } from "../styles/HeaderStyles";
import Review from "../assets/Review.png";
import renewPTImage from "../assets/nicoleRenew.jpg";
import { PrimaryButton } from "../subcomponents/CustomButton";
import { useSelector } from "react-redux";
import { useState } from "react";
import { signIn } from "../slices/AuthSlice";
import { useNavigate, Link } from "react-router-dom";
import { RootState, useAppDispatch } from "../store";
import { usePostHog } from "posthog-js/react";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import TextInputComponent from "../subcomponents/TextInputComponent";
import { Colors } from "../Colors";
import { sessionExpired } from "../slices/SessionSlice";
import { DateTime } from "luxon";

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const isLoading = useSelector((state: RootState) => state.auth.isLoading);
  const error = useSelector((state: RootState) => state.auth.message);
  const posthog = usePostHog();

  const handleLogin = async () => {
    const credentials = { email, password };
    const result = await dispatch(signIn(credentials));
  
    if (result.type === "auth/signIn/fulfilled") {
      // Reset session start time on successful login
      sessionStorage.setItem('sessionStartTime', DateTime.now().toMillis().toString());
  
      // Make sure the session is not expired
      dispatch(sessionExpired(false));
  
      // Capture login event
      posthog.capture("[PENCILED] login", { email });
  
      // Navigate to the dashboard
      navigate("/dashboard");
    } else {
      console.log("Login failed.");
    }
  }; 

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          width: { xs: "100%", md: "60%" },
          background: "#1B1F32",
          height: "100vh",
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          justifyContent: "left",
          alignItems: "left",
        }}
      >
        <WhitePenciledLogo style={{ width: "250px", margin: "auto", marginTop: "50px", color: "white" }} />
        <div style={{ display: "flex", flexDirection: "column", margin: "auto 100px 10px 100px" }}>
          <img src={Review} alt="Review" style={{ width: "150px" }} />
          <Typography variant="body1" color="#FFF" mt={2} style={{ textAlign: "left" }}>
            &quot;Penciled developed an innovative, clinic-specific solution to help us optimize waitlist backfilling and scheduling, ensuring our
            patients receive the care they need more efficiently.&quot;
          </Typography>
          <div style={{ display: "flex", marginBottom: "30px" }}>
            <img
              src={renewPTImage}
              alt="Review"
              style={{ width: "50px", height: "50px", borderRadius: "50%", marginTop: "10px", marginRight: "10px" }}
            />
            <div>
              <Typography variant="body1" color="#FFF" mt={2} style={{ textAlign: "left" }}>
                Nicole Sabes
              </Typography>
              <Typography variant="body2" color="#FFF" mt={0} style={{ textAlign: "left", marginBottom: "40px" }}>
                Clinic Director at Renew Physical Therapy
              </Typography>
            </div>
          </div>
        </div>
      </Box>

      <Box sx={{ padding: "4% 5%", display: "flex", flexDirection: "column", width: { xs: "100%", md: "60%" } }}>
        <Box sx={{ display: { xs: "flex", md: "none" }, marginBottom: "45%", marginTop: "10px", width: { xs: "100%" } }}>
          <WhitePenciledLogo style={{width: "160px"}} />
        </Box>
        <Typography variant="h4" fontWeight={"bold"} mb={2}>
          Log In
        </Typography>
        <Typography variant="body1" sx={{ mb: { xs: 0, sm: 10 } }}>
          Build generative AI voice agents for Healthie in minutes
        </Typography>

        <Box component="form" display="flex" flexDirection="column" sx={{ mt: 3, width: { xs: "100%", md: "500px" } }}>

          <TextInputComponent
            label="Email Address"
            labelVariant="h6"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <TextInputComponent
            label="Password"
            labelVariant="h6"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  sx={{ color: Colors.info }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />

          <Typography variant="body2" sx={{ mt: 0, marginLeft: "auto" }}>
            <Link to="/forgot-password" style={{textDecoration: "none", color: Colors.primary}}>Forgot Password?</Link>
          </Typography>


          <Box sx={{ marginTop: "20px" }}>
            <PrimaryButton 
              onClick={handleLogin} 
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : "Log In"}
            </PrimaryButton>
          </Box>
          {error && <Typography color="error">{error}</Typography>}
        </Box>

        <Typography variant="body2" sx={{ mt: 2 }}>
          New here? <Link style={{textDecoration: "none", color: Colors.primary}} to="/walkthrough/create-account">Create an account</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginPage;
