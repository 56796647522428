export const enum Colors {
  primary = "#5E78EE",
  primaryDark = "#4B66D1",

  secondary = "#1C223A",
  secondaryDark = "#15192E",

  tertiary = "#48A999",
  tertiaryDark = "#3B897E",

  success = "#00AC4F",
  error = "#EE2626",
  errorDark = "#C21E1E",
  warning = "#FCD554",
  info = "#9FA1A6",

  textfield = "#ffffff0a",
  bg = "#0F1327",
  bg2 = "#1C2138",
  bg3 = "#20253C",
  bgTextMsg = "#2C314F",
  link = "#0B5FFF",
  bgGrey = "#F1F3F4",

  confirmedColor1 = "#53C43B", // Green
  darkerGreen1 = "#3A8D2C",
  otherColor1 = "#BC4A43", // Red
  intermediateColor1 = "#F9D71C", // Yellow

  grey1 = "#ACACAC",
  grey2 = "#6c757d",
  grey3 = "#9197B3",
  blue1 = "#007bff",
  white = "#ffffff",
  coolWhite = "#f0f0f0",
}
