import React, { useEffect, useState } from "react";
import { Box, Checkbox, CircularProgress, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { DateTime } from "luxon";
import { updateOrganizationAttribute } from "../../slices/AuthSlice";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { PrimaryButton } from "../../subcomponents/CustomButton";
import { FrontendUser, QuestionAnswerPair } from "../../types";
import { addQuestionsAndAnswers, deleteQuestion, getQuestionsAndAnswers, updateAnswer } from "../../slices/QuestionAndAnswerSlice";
import { Colors } from "../../Colors";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { FooterBox, HeaderBox } from "../../subcomponents/SidebarComponents";
import { InputStyles } from "../../styles/GeneralStyles";
import TextInputComponent from "../../subcomponents/TextInputComponent";

interface OrgInfoProps {
  onClick: () => void;
  onClose: () => void;
}

enum BusinessSetupStep {
  BusinessInfo = 0,
  QuestionBank = 1,
}

const OrgInfo: React.FC<OrgInfoProps> = ({ onClick, onClose }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const dispatch = useAppDispatch();

  const [currentStep, setCurrentStep] = useState(BusinessSetupStep.BusinessInfo);

  const [orgName, setOrgName] = useState<string>("");
  const [callbackNumber, setCallbackNumber] = useState<string>("");
  const [liveTransferNumber, setLiveTransferNumber] = useState<string>("");
  const [workingHoursStart, setWorkingHoursStart] = useState<DateTime | null>(null);
  const [workingHoursEnd, setWorkingHoursEnd] = useState<DateTime | null>(null);
  const [formValid, setFormValid] = useState<boolean>(() => {
    return orgName !== "" && callbackNumber !== "" && liveTransferNumber !== "" && workingHoursStart !== null && workingHoursEnd !== null;
  });
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const [newQuestion, setNewQuestion] = useState("");
  const [newAnswer, setNewAnswer] = useState("");
  const [newSource, setNewSource] = useState("");
  const [showSourceInput, setShowSourceInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);

  const questionsAndAnswers = useSelector((state: RootState) => state.questionsAndAnswers.questionsAndAnswers || []);
  const loadingQuestionsAndAnswers = useSelector((state: RootState) => state.questionsAndAnswers.loading);
  const loadingQuestionsAndAnswersMsg = useSelector((state: RootState) => state.questionsAndAnswers.loadingMsg);

  const [questionAnswerPairs, setQuestionAnswerPairs] = useState<QuestionAnswerPair[]>(questionsAndAnswers);

  const { showMessage } = useSnackbar();

  useEffect(() => {
    setFormValid(orgName !== "" && callbackNumber !== "" && workingHoursStart !== null && workingHoursEnd !== null);
  }, [orgName, callbackNumber, workingHoursStart, workingHoursEnd]);

  const onSubmit = async () => {
    if (!user?.token) { return; }
    setLoading(true);
    try {
      if (workingHoursStart && workingHoursEnd) {
        await dispatch(updateOrganizationAttribute({ token: user.token, attribute: "name", value: orgName }));
        await dispatch(updateOrganizationAttribute({ token: user.token, attribute: "phoneNumber", value: callbackNumber }));
        await dispatch(updateOrganizationAttribute({ token: user.token, attribute: "workingHoursStart", value: workingHoursStart.toISO() }));
        await dispatch(updateOrganizationAttribute({ token: user.token, attribute: "workingHoursEnd", value: workingHoursEnd.toISO() }));
        setCurrentStep(BusinessSetupStep.QuestionBank);
      } else {
        setErrorMessage("Working hours must be set");
      }
    } catch (error) {
      setErrorMessage("Failed to update organization info");
    }
    setLoading(false);
  };

  const handleCompleteSetup = async () => {
    setCompleteLoading(true);
    try {
      onClick();
      showMessage(
        "Business setup completed",
        <span>
          You’ve successfully set up <strong>{orgName}</strong>
        </span>,
        "success"
      );
      if (orgName && callbackNumber && workingHoursStart && workingHoursEnd) {
        onClose();
      } else {
        setErrorMessage("All fields must be filled out");
      }
    } catch (error) {
      setErrorMessage("Failed to complete setup");
    }
    setCompleteLoading(false);
  };

  useEffect(() => {
    setQuestionAnswerPairs(questionsAndAnswers);
  }, [questionsAndAnswers]);

  useEffect(() => {
    const getQuestionsAndAnswersLocal = async () => {
      if (!user?.token) { return; }
      await dispatch(getQuestionsAndAnswers({ token: user.token })); // expensive op $$$ -> avoid when possible!
    };
    getQuestionsAndAnswersLocal();
  }, []);

  const handleAddQuestionAnswer = () => {
    if (!user?.token) { return; }
    if (newQuestion && newAnswer) {
      const newPair: QuestionAnswerPair = {
        id: "",
        question: newQuestion,
        answer: newAnswer,
        source: newSource,
      };
      setQuestionAnswerPairs([...questionAnswerPairs, newPair]);
      setNewQuestion("");
      setNewAnswer("");
      setNewSource("");
      setShowSourceInput(false);
      dispatch(addQuestionsAndAnswers({ token: user.token, questionsAndAnswers: [newPair] }));
    }
  };

  const handleUpdateQuestionAnswer = (index: number) => {
    if (!user?.token) { return; }
    const pair = questionAnswerPairs[index];
    if (pair) {
      dispatch(updateAnswer({ token: user.token, pair: pair }));
    }
  };

  const handleDeleteQuestionAnswer = (index: number) => {
    if (!user?.token) { return; }
    const pair = questionAnswerPairs[index];
    if (pair) {
      dispatch(deleteQuestion({ token: user.token, vectorId: pair.id }));
      setQuestionAnswerPairs(questionAnswerPairs.filter((_, i) => i !== index));
    }
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "100%",
        paddingBottom: "50px",
        paddingTop: "25px",
        paddingRight: "20px",
      }}
    >
      <HeaderBox title="Business setup" onClose={onClose} />

      {currentStep === BusinessSetupStep.BusinessInfo && (
        <>
          <Typography variant="body1" fontSize={"1.1rem"} margin={"35px 0 10px 0"}>
            Tell us about your business
          </Typography>

          <TextInputComponent
            label="Business name"
            placeholder="Enter your business name"
            value={orgName}
            onChange={(e) => setOrgName(e.target.value)}
          />

          <TextInputComponent
            label="Business phone number"
            placeholder="Enter phone number"
            value={callbackNumber}
            onChange={(e) => setCallbackNumber(e.target.value)}
            helperText="*If the need arises, AI agents will transfer the call to this phone number."
          />

          <Typography variant="body2" style={{ margin: "20px 0 10px 0" }}>
            Working hours
          </Typography>
          <Box display="flex" flexDirection="row" gap="20px">
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <TimePicker
                label="Start Time"
                value={workingHoursStart}
                onChange={(date: DateTime | null) => setWorkingHoursStart(date)}
                sx={InputStyles}
              />

              <TimePicker label="End Time" value={workingHoursEnd} onChange={(date: DateTime | null) => setWorkingHoursEnd(date)} sx={InputStyles} />
            </LocalizationProvider>
          </Box>

          <Box display="flex" alignItems={"center"}>
            <Checkbox name="weekday" sx={{ color: "#fff" }} />
            <Typography variant="body2">
              Weekdays <span style={{ color: Colors.info }}>(Mon - Fri)</span>
            </Typography>
          </Box>

          <Box display="flex" alignItems={"center"}>
            <Checkbox name="weekend" sx={{ color: "#fff" }} />
            <Typography variant="body2">
              Weekends <span style={{ color: Colors.info }}>(Sat - Sun)</span>
            </Typography>
          </Box>

          <Typography variant="body2" color="#9FA1A6" mt={0.5}>
            *AI agents will be allowed to call within this time range only.
          </Typography>
        </>
      )}

      {currentStep === BusinessSetupStep.QuestionBank && (
        <>
          <Box display="flex" flexDirection="column" mt={1} width="100%">
            <Box display={"flex"} alignItems={"baseline"} gap="10px">
              <Typography variant="body1" fontSize={"1.1rem"} marginTop={"20px"}>
                Question Bank
              </Typography>
              <CircularProgress color="primary" size={20} thickness={5} style={{ display: loadingQuestionsAndAnswers ? "block" : "none" }} />
            </Box>
            <Typography variant="body2" fontSize={"0.9rem"} marginTop={"5px"} color={Colors.info}>
              You can create some custom replies for the AI agents to utilize when conversing with patients. You can always manage this question bank
              in your settings.
            </Typography>

            <Box
              display="flex"
              flexDirection="column"
              mt={1}
              gap={2}
              sx={{
                marginBottom: { xs: "0px", sm: "25px" },
              }}
            >
              {questionAnswerPairs.map((pair, index) => {
                const isEditing = editingIndex === index;

                return (
                  <Box
                    key={index}
                    sx={{
                      background: Colors.bg2,
                      padding: "10px",
                      borderRadius: "15px",
                      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
                      marginBottom: "10px",
                    }}
                  >
                    {isEditing ? (
                      <>
                        <Box display={"flex"} justifyContent={"space-between"}>
                          <Typography
                            sx={{
                              padding: "5px",
                              fontSize: "0.85rem",
                              color: "#FFFFFF",
                              fontWeight: "bold",
                            }}
                          >
                            Question:
                          </Typography>
                          <TextField
                            value={pair.question}
                            onChange={(e) =>
                              setQuestionAnswerPairs(questionAnswerPairs.map((p, i) => (i === index ? { ...p, question: e.target.value } : p)))
                            }
                            sx={{
                              width: "85%",
                              marginBottom: "8px",
                              backgroundColor: Colors.textfield,
                              "& .MuiInputBase-input": {
                                padding: "5px 10px",
                                fontSize: "0.85rem",
                                color: "#FFFFFF",
                              },
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "0.5px solid none",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "0.5px solid Colors.primary",
                                },
                              },
                            }}
                          />
                        </Box>

                        <Box display={"flex"} justifyContent={"space-between"}>
                          <Typography
                            sx={{
                              padding: "5px",
                              fontSize: "0.85rem",
                              color: "#FFFFFF",
                              fontWeight: "bold",
                            }}
                          >
                            Answer:
                          </Typography>
                          <TextField
                            value={pair.answer}
                            onChange={(e) =>
                              setQuestionAnswerPairs(questionAnswerPairs.map((p, i) => (i === index ? { ...p, answer: e.target.value } : p)))
                            }
                            sx={{
                              width: "85%",
                              marginBottom: "8px",
                              backgroundColor: Colors.textfield,
                              "& .MuiInputBase-input": {
                                padding: "5px 10px",
                                fontSize: "0.85rem",
                                color: "#FFFFFF",
                              },
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "0.5px solid none",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "0.5px solid Colors.primary",
                                },
                              },
                            }}
                          />
                        </Box>

                        <Box display={"flex"} justifyContent={"space-between"}>
                          <Typography
                            sx={{
                              padding: "5px",
                              fontSize: "0.85rem",
                              color: "#FFFFFF",
                              fontWeight: "bold",
                            }}
                          >
                            Link:
                          </Typography>
                          <TextField
                            placeholder="eg. https://www.example.com"
                            value={pair.source}
                            onChange={(e) =>
                              setQuestionAnswerPairs(questionAnswerPairs.map((p, i) => (i === index ? { ...p, source: e.target.value } : p)))
                            }
                            sx={{
                              width: "85%",
                              marginBottom: "8px",
                              backgroundColor: Colors.textfield,
                              "& .MuiInputBase-input": {
                                padding: "5px 10px",
                                fontSize: "0.85rem",
                                color: Colors.info,
                              },
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "0.5px solid none",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "0.5px solid Colors.primary",
                                },
                              },
                            }}
                          />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            padding: "5px",
                            fontSize: "0.85rem",
                            color: "#FFFFFF",
                            fontWeight: "bold",
                          }}
                        >
                          {pair.question}
                        </Typography>

                        <Typography
                          sx={{
                            padding: "5px",
                            fontSize: "0.85rem",
                            color: "#FFFFFF",
                          }}
                        >
                          {pair.answer}
                        </Typography>

                        <Typography
                          sx={{
                            padding: "0px 5px 5px 5px",
                            fontSize: "0.85rem",
                            color: Colors.info,
                          }}
                        >
                          {pair.source}
                        </Typography>
                      </>
                    )}

                    <Box display="flex" gap={1} marginLeft={"auto"} marginTop={"10px"}>
                      <Typography
                        onClick={() => {
                          if (isEditing) {
                            handleUpdateQuestionAnswer(index);
                          }
                          setEditingIndex(isEditing ? null : index);
                        }}
                        sx={{ padding: "0 5px", fontSize: "0.85rem", fontWeight: "bold", color: Colors.primary, cursor: "pointer" }}
                      >
                        {loadingQuestionsAndAnswersMsg === "Updating question..." && isEditing ? (
                          <CircularProgress size={20} />
                        ) : isEditing ? (
                          "Update"
                        ) : (
                          "Edit"
                        )}
                      </Typography>

                      <Typography
                        onClick={() => handleDeleteQuestionAnswer(index)}
                        sx={{ padding: "0 5px", fontSize: "0.85rem", fontWeight: "bold", color: Colors.error, cursor: "pointer" }}
                      >
                        {loadingQuestionsAndAnswersMsg === "Deleting question..." ? <CircularProgress size={20} /> : "Delete"}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              sx={{
                background: Colors.bg2,
                padding: "15px",
                borderRadius: "15px",
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Box>
                <Typography variant="body2" width={"100%"} fontWeight={"bold"} mb={1}>
                  Question
                </Typography>

                <TextField
                  placeholder="eg. How can I locate your office?"
                  value={newQuestion}
                  onChange={(e) => setNewQuestion(e.target.value)}
                  sx={{
                    width: "100%",
                    backgroundColor: Colors.textfield,
                    borderRadius: "10px",
                    "& .MuiInputBase-input": {
                      padding: "10px",
                      fontSize: "0.85rem",
                      color: "#FFFFFF",
                    },
                  }}
                />
              </Box>

              <Box>
                <Typography variant="body2" width={"100%"} fontWeight={"bold"} mb={1}>
                  Answer
                </Typography>
                <TextField
                  placeholder="Enter your answer"
                  value={newAnswer}
                  onChange={(e) => setNewAnswer(e.target.value)}
                  sx={{
                    width: "100%",
                    backgroundColor: Colors.textfield,
                    borderRadius: "10px",
                    "& .MuiInputBase-input": {
                      padding: "10px",
                      fontSize: "0.85rem",
                      color: "#FFFFFF",
                    },
                  }}
                />
              </Box>

              <Box>
                <Typography variant="body2" width={"100%"} fontWeight={"bold"} mb={1}>
                  Resource link <span style={{ fontWeight: "normal", fontSize: "0.8rem", color: Colors.info }}>(optional)</span>
                </Typography>

                <TextField
                  placeholder="eg. https://www.example.com"
                  value={newSource}
                  onChange={(e) => setNewSource(e.target.value)}
                  sx={{
                    width: "100%",
                    backgroundColor: Colors.textfield,
                    borderRadius: "10px",
                    "& .MuiInputBase-input": {
                      padding: "10px",
                      fontSize: "0.85rem",
                      color: "#FFFFFF",
                    },
                  }}
                />
              </Box>

              <PrimaryButton
                onClick={handleAddQuestionAnswer}
                sx={{
                  padding: "5px 10px",
                  fontSize: "0.85rem",
                  marginTop: "5px",
                  marginRight: "auto",
                }}
              >
                {loadingQuestionsAndAnswersMsg === "Adding questions and answers..." ? <CircularProgress size={20} color="secondary" /> : "Add"}
              </PrimaryButton>
            </Box>
          </Box>
        </>
      )}

      {currentStep === BusinessSetupStep.BusinessInfo ? (
        <FooterBox onClick={onSubmit} disabled={!formValid} buttonText="Continue" loading={loading} />
      ) : (
        <FooterBox onClick={handleCompleteSetup} disabled={false} buttonText="Complete Setup" loading={completeLoading} />
      )}
    </Box>
  );
};

export default OrgInfo;
