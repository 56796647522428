import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Call, Agent, CallStatuses, OutcomeSentiment, FrontendUser } from "../types";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SignoutHeader from "../subcomponents/SignoutHeader";
import { DateTime } from "luxon";
import { Colors } from "../Colors";
import { Typography } from "@mui/material";
import FilterForm from "../forms/FilterForm";
import { nextCallsPaginated, refreshCallsPaginated } from "../slices/CallSlice";
import { selectAgents } from "../slices/AgentSlice";
import { SecondaryButton } from "../subcomponents/CustomButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Loader from "../subcomponents/Loader";
import { usePostHog } from "posthog-js/react";
import { callStatusColor, callStatusDisplay, convertSnakeCaseToOfficial, getOutcomeColor } from "../utils/utils";
import { Sidebar } from "../styles/GeneralStyles";
import ExportModal from "../forms/ExportModal";

const PAGE_SIZE = 8;

type OnRowClick = (call: Call) => void;

const ConversationHistoryRow: React.FC<{ call: Call; agents: Agent[]; onRowClick: OnRowClick }> = ({
  call,
  agents,
  onRowClick,
}) => {
  const callDate = DateTime.fromISO(call.startedAt);
  const date = callDate.toFormat("M/d");
  const time = callDate.toFormat("h:mm a");

  // Find the agent's name by matching the agentId
  const agent = agents.find((a) => a.agentId === call.agentId);
  const agentName = agent ? agent.name : "N/A";
  const agentType = agent ? agent.agentType : "N/A";
  const outcome = call.outcome as string;
  const outcomeSentiment = call.outcomeSentiment as OutcomeSentiment;
  const status = call.status as keyof typeof CallStatuses;

  const patientName = `${call.patientFirstName} ${call.patientLastName}`;

  return (
    <Box
      onClick={() => onRowClick(call)}
      sx={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "20px",
        backgroundColor: Colors.bg2,
        padding: { xs: "10px 15px", sm: "20px 45px" },
        borderRadius: "25px",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: Colors.secondaryDark,
        },
      }}
    >
      <Typography
        variant="body2"
        sx={{
          marginRight: { xs: "20px", md: "35px", lg: "50px" },
          color: Colors.grey3,
          width: { xs: "25%", md: "12%" },
          fontSize: { xs: "0.85rem", sm: "0.9rem" },
        }}
      >
        <strong>{date}</strong>
        <br /> {time}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: { xs: "90%", md: "90%" },
          alignItems: { xs: "normal", sm: "center" },
          justifyContent: { xs: "normal", sm: "space-between" },
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" sx={{ fontSize: { xs: "1.2rem", sm: "1.3rem" } }}>
            {patientName}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: Colors.grey3, fontSize: { xs: "0.9rem", sm: "1rem" } }}>
            Agent: {agentName} ({agentType})
          </Typography>
        </div>

        <Box sx={{display: "flex", flexDirection:{xs: "column", sm: "row"}, justifyContent: {xs: "normal", sm: "space-between"}, gap: {xs: 0, sm: 8} }}>
        {status && (
          <Box sx={{display: 'flex', flexDirection: {xs: 'row', sm: 'column'}, alignItems: 'center', gap: {xs: 1, sm: 0} }}>
            <Typography variant='subtitle2' style={{color: Colors.grey3, fontSize: "0.9rem"}}>
              Call status:
            </Typography> 

            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "1rem", sm: "1.1rem" }, marginLeft: { xs: "normal", sm: "auto" }, color: callStatusColor(status as CallStatuses)}}
            > 
              
              {" "}
              {callStatusDisplay(status as CallStatuses)}
            </Typography>
          </Box>
        )}

        {outcome && (
          <Box sx={{display: 'flex', flexDirection: {xs: 'row', sm: 'column'}, alignItems: 'center', gap: {xs: 1, sm: 0} }}>
            <Typography variant='subtitle2' style={{color: Colors.grey3, fontSize: "0.9rem"}}>
              Call outcome:
            </Typography>

            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "1rem", sm: "1.1rem" }, marginLeft: { xs: "normal", sm: "auto" }, color: getOutcomeColor(outcomeSentiment)}}
            >
              {" "}
              {convertSnakeCaseToOfficial(outcome)}
            </Typography>
          </Box>
        )}
        </Box>
        
      </Box>
      <ChevronRightIcon onClick={() => onRowClick(call)} sx={{ display: { xs: "block", sm: "none" }, marginLeft: "auto" }} />
    </Box>
  );
};

const ConversationHistory: React.FC = () => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const posthog = usePostHog();
  const calls: Call[] | null = useSelector((state: RootState) => state.calls.calls);
  const lastEvaluatedKey: unknown = useSelector((state: RootState) => state.calls.lastEvaluatedKey);
  const finalPage: boolean = useSelector((state: RootState) => state.calls.finalPage);
  const agents = useSelector(selectAgents);

  const [agentsFilter, setAgentsFilter] = useState<string[] | undefined>(agents.map((a) => a.agentId));
  const [datesFilter, setDatesFilter] = useState<[DateTime | null, DateTime | null] | undefined>(undefined);
  const [index, setIndex] = useState<number>(0);

  const loading = useSelector((state: RootState) => state.calls.loading);

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const [endOfList, setEndOfList] = useState<boolean>(calls ? calls.length <= (index + 1) * PAGE_SIZE : false);
  const [pageCalls, setPageCalls] = useState<Call[] | null>(calls ? calls.slice(index * PAGE_SIZE, (index + 1) * PAGE_SIZE) : []);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (calls) {
      setPageCalls(calls.slice(index * PAGE_SIZE, (index + 1) * PAGE_SIZE));
      setEndOfList(calls.length <= (index + 1) * PAGE_SIZE);
    }
  }, [calls]);

  const setFilter = (agents: string[], dates: [DateTime | null, DateTime | null]) => {
    setAgentsFilter(agents);
    setDatesFilter(dates);
    getCallsPaginatedInitial(agents, dates);
    setFilterModalOpen(false);
  };

  const getCallsPaginatedInitial = async (agents?: string[], dates?: [DateTime | null, DateTime | null]) => {
    if (!user?.token) { return; }
    dispatch(refreshCallsPaginated({ token: user.token, agentIds: agents, dateRange: dates }));
    setIndex(0);
  };

  const refreshCalls = async () => {
    if (!user?.token) { return; }
    await dispatch(refreshCallsPaginated({ token: user.token, agentIds: agentsFilter, dateRange: datesFilter }));
    setIndex(0);
  };

  const getCallsPaginated = async () => {
    if (!user?.token) { return; }
    await dispatch(nextCallsPaginated({ token: user.token, lastEvaluatedKey, agentIds: agentsFilter, dateRange: datesFilter }));
    setIndex(index + 1);
  };

  const prevPage = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const nextPage = () => {
    // check to see if we already have the calls loaded
    if (calls && calls.length > (index + 1) * PAGE_SIZE) {
      setIndex(index + 1);
    } else if (!finalPage) {
      getCallsPaginated();
    }
  };

  useEffect(() => {
    getCallsPaginatedInitial(agentsFilter);
  }, []);

  return (
    <Box>
      <SignoutHeader />
      <Box sx={{ width: { xs: "100%", sm: "95%" }, color: "#FFF" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: { xs: "75px 0 20px 0", lg: "20px 0" } }}>
          <Typography variant="h5" sx={{ color: "#FFF", fontSize: { xs: "1.3rem", sm: "1.8rem" } }}>
            Conversation History
          </Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
          <SecondaryButton onClick={() => setFilterModalOpen(true)} sx={{ padding: { xs: "3px 15px", sm: "5px 20px" } }}>
            Filter
          </SecondaryButton>
          <SecondaryButton onClick={() => setExportModalOpen(true)} sx={{ padding: { xs: "3px 15px", sm: "5px 20px" } }}>
            Export
          </SecondaryButton>
          </Box>
        </Box>
        {loading ? (
          <Loader message="" size={60} customStyles={{ height: "100%", marginTop: "30vh" }} />
        ) : calls && calls.length > 0 ? (
          <>
            {pageCalls && pageCalls.map((call: Call) => (
              <ConversationHistoryRow
                key={call.scheduledCallId}
                call={call}
                agents={agents}
                onRowClick={() => {
                  posthog?.capture("[PENCILED] conversation_history_row_clicked", { callId: call.callId });
                  navigate(`/dashboard/conversation-history/calls/${call?.callId}`);
                }}
              />
            ))}
            <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
              <SecondaryButton onClick={refreshCalls}>Refresh</SecondaryButton>
              <SecondaryButton onClick={prevPage} disabled={index === 0}>
                Prev
              </SecondaryButton>
              <SecondaryButton onClick={nextPage} disabled={endOfList && finalPage}>
                Next
              </SecondaryButton>
            </div>
          </>
        ) : (
          <Typography variant="body1" sx={{ textAlign: "center", margin: "auto", marginTop: "10%" }}>
            No conversation history found.
          </Typography>
        )}
      </Box>

      <Sidebar className={filterModalOpen || exportModalOpen ? "open" : ""}>
        {filterModalOpen && (
          <FilterForm 
            setFilter={setFilter} 
            agentsFilter={agentsFilter} 
            datesFilter={datesFilter} 
            agentsSelector={true} 
            onClose={() => setFilterModalOpen(false)}
          />
        )}
        {exportModalOpen && (
          <ExportModal
            agentsSelector={true}
            mainAgentsFilter={agentsFilter}
            onClose={() => setExportModalOpen(false)}
          />
        )}
      </Sidebar>
    </Box>
  );
};

export default ConversationHistory;
