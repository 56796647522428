import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { HealthieWaitlistPatient, RawHealthiePatient } from "../types";
import { api, convertToCallablePhoneNumber } from "../utils/utils";

export interface PatientState {
  patients: HealthieWaitlistPatient[];
  patientMap: { [patientId: string]: HealthieWaitlistPatient };
  errorMsg: string | undefined;
  loadingFetchPatients: boolean;
  loadingDeletePatient: boolean;
  loadingUpdatePatient: boolean;
  loadingUpdatePriorities: boolean;
  loadingCreatePatient: boolean;
  loadingGetPatient: boolean;
}

const initialState: PatientState = {
  patients: [],
  patientMap: {},
  errorMsg: "",
  loadingFetchPatients: false,
  loadingDeletePatient: false,
  loadingUpdatePatient: false,
  loadingUpdatePriorities: false,
  loadingCreatePatient: false,
  loadingGetPatient: false,
};

export const getWaitlistPatients = createAsyncThunk<HealthieWaitlistPatient[], { token: string; quietly?: boolean }, { rejectValue: Error }>(
  "waitlist/getWaitlistPatients",
  async ({ token, quietly = false }, { rejectWithValue }) => {
    try {
      const response = await api.get("/patients/waitlist", token);

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to fetch waitlist patients"));
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to fetch waitlist patients: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const updatePatient = createAsyncThunk<void, { token: string; patientId: string; params: any }, { rejectValue: Error }>(
  "waitlist/updatePatient",
  async ({ token, patientId, params }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.patch(`/patients/waitlist/${patientId}`, token, params);
      if (response.status !== 200) {
        throw new Error("Failed to update patient");
      }

      // Dispatch getWaitlistPatient to fetch the updated patient data
      await dispatch(getWaitlistPatient({ token, patientId }));
    } catch (error: any) {
      return rejectWithValue(new Error("Failed to update patient: " + (error.response?.data?.error || error.message || "Unknown error")));
    }
  }
);

export const getWaitlistPatient = createAsyncThunk<HealthieWaitlistPatient, { token: string; patientId: string }, { rejectValue: Error }>(
  "waitlist/getWaitlistPatient",
  async ({ token, patientId }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/patients/waitlist/${patientId}`, token);

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to fetch patient details"));
      }

      return response.data.patient;
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to fetch patient details: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const deletePatient = createAsyncThunk<string, { token: string; patientId: string }, { rejectValue: Error }>(
  "waitlist/deletePatient",
  async ({ token, patientId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/patients/${patientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to delete patient"));
      }

      // Fetch updated waitlist patients after deletion
      await dispatch(getWaitlistPatients({ token, quietly: false }));

      return patientId;
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to delete patient: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const createPatient = createAsyncThunk<
  HealthieWaitlistPatient,
  {
    token: string;
    selectedHealthiePatient: RawHealthiePatient;
    timezone: string;
    language: string;
    textEnabled: boolean;
    callEnabled: boolean;
    availableDates: string[];
    selectedProviders: string[];
    selectedAppointmentTypes: string[];
    selectedContactTypes: string[];
    patientGroupId?: string;
  },
  { rejectValue: Error }
>(
  "waitlist/createPatient",
  async (
    {
      selectedHealthiePatient,
      timezone,
      language,
      textEnabled,
      callEnabled,
      availableDates,
      selectedProviders,
      selectedAppointmentTypes,
      selectedContactTypes,
      token,
      patientGroupId,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/patients`,
        {
          healthiePatientId: selectedHealthiePatient?.healthiePatientId,
          firstName: selectedHealthiePatient?.firstName,
          lastName: selectedHealthiePatient?.lastName,
          phoneNumber: convertToCallablePhoneNumber(selectedHealthiePatient?.phoneNumber),
          email: selectedHealthiePatient?.email,
          timezone,
          language,
          textEnabled,
          callEnabled,
          availableDates: availableDates,
          providerIds: selectedProviders,
          appointmentTypeIds: selectedAppointmentTypes,
          contactTypes: selectedContactTypes,
          dob: selectedHealthiePatient?.dob,
          patientGroupId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(addPatient(response.data.patient));

      if (response.status !== 201) {
        return rejectWithValue(new Error("Failed to create patient"));
      }

      return response.data.patient;
    } catch (error: any) {
      return rejectWithValue(new Error("Failed to create patient: " + (error.response?.data?.error || error.message || "Unknown error")));
    }
  }
);

export const updatePriorities = createAsyncThunk<void, { token: string; patients: HealthieWaitlistPatient[] }, { rejectValue: Error }>(
  "waitlist/updatePriorities",
  async ({ token, patients }, { rejectWithValue }) => {
    try {
      const priorities = patients.map((patient, index) => ({
        patientId: patient.patientId,
        priority: index + 1,
      }));

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/patients/priorities`, priorities, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to update priorities"));
      }
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to update priorities: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

const patientSlice = createSlice({
  name: "patientSlice",
  initialState,
  reducers: {
    addPatient: (state, action: PayloadAction<HealthieWaitlistPatient>) => {
      state.patients.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPatient.pending, (state) => {
        state.loadingCreatePatient = true;
        state.errorMsg = "";
      })
      .addCase(createPatient.fulfilled, (state, action) => {
        state.loadingCreatePatient = false;
      })
      .addCase(createPatient.rejected, (state, action) => {
        state.loadingCreatePatient = false;
        state.errorMsg = action.payload?.message;
      })
      .addCase(updatePatient.pending, (state) => {
        state.loadingUpdatePatient = true;
        state.errorMsg = "";
      })
      .addCase(updatePatient.fulfilled, (state) => {
        state.loadingUpdatePatient = false;
        // The patient object will be updated by getWaitlistPatient, so no direct changes here
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.loadingUpdatePatient = false;
        state.errorMsg = action.payload?.message;
      })
      .addCase(getWaitlistPatient.pending, (state) => {
        state.loadingGetPatient = true;
        state.errorMsg = "";
      })
      .addCase(getWaitlistPatient.fulfilled, (state, action) => {
        state.loadingGetPatient = false;

        const index = state.patients.findIndex((patient) => patient.patientId === action.payload.patientId);

        if (index !== -1) {
          state.patients[index] = action.payload;
        } else {
          state.patients.push(action.payload);
        }
      })
      .addCase(getWaitlistPatients.pending, (state, action) => {
        // Only set loadingFetchPatients to true if quietly is false or undefined
        if (!action.meta.arg.quietly) {
          state.loadingFetchPatients = true;
        }
        state.errorMsg = "";
      })
      .addCase(getWaitlistPatients.fulfilled, (state, action) => {
        state.loadingFetchPatients = false;
        state.patients = action.payload;
        const patientMap: { [patientId: string]: HealthieWaitlistPatient } = {};
        action.payload.forEach((patient) => {
          patientMap[patient.patientId] = patient;
        });
        state.patientMap = patientMap;
      })
      .addCase(getWaitlistPatients.rejected, (state, action) => {
        state.loadingFetchPatients = false;
        state.errorMsg = action.payload?.message;
      })
      .addCase(deletePatient.pending, (state) => {
        state.loadingDeletePatient = true;
        state.errorMsg = "";
      })
      .addCase(deletePatient.fulfilled, (state, action) => {
        state.loadingDeletePatient = false;
        state.patients = state.patients.filter((patient) => patient.patientId !== action.payload);
      })
      .addCase(deletePatient.rejected, (state, action) => {
        state.loadingDeletePatient = false;
        state.errorMsg = action.payload?.message;
      })
      .addCase(updatePriorities.pending, (state) => {
        state.loadingUpdatePriorities = true;
        state.errorMsg = "";
      })
      .addCase(updatePriorities.fulfilled, (state) => {
        state.loadingUpdatePriorities = false;
      })
      .addCase(updatePriorities.rejected, (state, action) => {
        state.loadingUpdatePriorities = false;
        state.errorMsg = action.payload?.message;
      });
  },
});

export const { addPatient } = patientSlice.actions;

export const selectWaitlistState = (state: RootState) => state.patients;

export default patientSlice.reducer;
